import cn from 'classnames';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import TextField from 'components/form/TextField';
import styles from './index.module.pcss';
import { EDIT_MODE } from './index';

interface InputRuleProps {
	editMode: EDIT_MODE;
	first?: boolean; // This props needed to determine the width of the first rule of empty rule list
	focused: boolean; // This prop look at the index of rule in the group
	focusRefresher?: string;

	onFocus: (ruleId: number) => void;
	onChange: (value: string, ruleId: number) => void;
	onKeyDown: (e: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) => void;

	ruleId: number;
	value: string;
}

const EMPTY_WIDTH = 4;
const EMPTY_WIDTH_FIRST = '100%';
const EMPTY_FOCUSED_WIDTH = 16;

export function RuleItem({
	editMode,
	first,
	focusRefresher,
	focused,
	onFocus,
	onChange,
	onKeyDown: onKeyDownProps,
	ruleId,
	value,
}: InputRuleProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const valueRef = useRef<HTMLInputElement>(null);
	const [width, setWidth] = useState<number | string>(EMPTY_WIDTH);

	// Determine te width of empty rule
	useEffect(() => {
		if (valueRef?.current?.offsetWidth) {
			setWidth(valueRef?.current?.offsetWidth);
		} else {
			setWidth(
				first ? EMPTY_WIDTH_FIRST : editMode === 'values' ? EMPTY_WIDTH : EMPTY_FOCUSED_WIDTH
			);
		}
	}, [value, first]);

	// Set|unset focus in the input
	useEffect(() => {
		if (!focused) {
			inputRef.current?.blur();
		} else {
			inputRef.current?.focus();
		}
	}, [focused, focusRefresher]);

	function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
		if (
			event.code === 'Enter' ||
			(event.code === 'Comma' && editMode === 'values') ||
			(event.code === 'Semicolon' && editMode === 'type') ||
			event.code === 'ArrowLeft' ||
			event.code === 'ArrowRight' ||
			event.code === 'Backspace' ||
			event.code === 'Escape'
		) {
			onKeyDownProps(event, value, ruleId);
		}
	}

	return (
		<>
			{/* TODO: maybe hide it when unfocused */}
			<div ref={valueRef} className={styles.invisibleValue}>
				{value}
			</div>

			<TextField
				placeholder={first ? 'Enter a resource' : ''}
				onKeyDown={onKeyDown}
				className={styles.inputRoot}
				style={{ width }}
				InputProps={{
					inputProps: {
						tabIndex: editMode ? 0 : -1,
					},
					inputRef,
					onBlur: () => inputRef.current?.blur(),
					onFocus: () => onFocus(ruleId),
					classes: {
						root: styles.inputRoot,
						input: cn(styles.input, {
							[styles.emptyInput]: value.length === 0,
							[styles.active]: editMode === 'type',
							[styles.emptyCategoryInput]: (value.length === 0 && editMode === 'type') || first,
							[styles.emptyServiceInput]: value.length === 0 && editMode === 'values',
						}),
						notchedOutline: cn(styles.notchedOutline),
					},
				}}
				value={value}
				onChange={({ target }) => onChange(target.value, ruleId)}
				helperText={null}
			/>
		</>
	);
}
