import cn from 'classnames';
import { ReactNode, useContext } from 'react';
import { StateContext } from '../RuleGroup';
import styles from './index.module.pcss';

interface ColoredWrapperProps {
	children: ReactNode;
	groupId?: number;
	onBlur?: () => void;
	onClick: () => void;
	onHover?: () => void;
	valueMode?: boolean;
}

export type ColoredWrapperState =
	| 'unfocusedEmptyFirst'
	| 'unfocusedEmpty'
	| 'unfocusedWithContent'
	| 'unfocusedHoveredWithContent'
	| 'focusedWithContent'
	| 'focusedEmpty';

export function ColoredWrapper({
	children,
	groupId,
	onBlur,
	onClick,
	onHover,
	valueMode,
}: ColoredWrapperProps) {
	const state = useContext(StateContext);

	return (
		<div
			className={cn(styles.container, {
				[styles[state]]: state,
				[styles.valueMode]: valueMode,
			})}
			data-group={groupId}
			onClick={onClick}
			onMouseEnter={onHover}
			onMouseLeave={onBlur}
		>
			{children}
		</div>
	);
}
