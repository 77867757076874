import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import AssetChip from 'components/AssetChip';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { methodOrProtocol, EndpointsTableItem } from 'models/endpointsV2/dto';
import { lastSeen } from 'services/lastSeen';
import { toAbbreviatedNumber, toRPS } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import stylesList from '../index.module.css';
import styles from './index.module.css';

type Props = {
	endpoint: EndpointsTableItem;
	search: string;
	oneAssetMode?: boolean;
	dataTest?: string;
};

function EndpointItem(props: Props) {
	const { endpoint, oneAssetMode, search, dataTest } = props;

	const {
		id,
		asset_name,
		asset_is_external,
		data_types,
		url,
		host,
		namespace,
		sensitivity,
		last_seen,
		process_name,
		is_encrypted,
		is_mesh_network,
		estimated_requests,
	} = endpoint;

	const history = useHistory();
	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	function clickHandler() {
		history.push(`${PATHS.SAMPLE_V2}?id=${id}&direction=request`);
	}

	const assetName = asset_name + (process_name ? `: ${process_name}` : '');

	return (
		<GridRow
			className={oneAssetMode ? stylesList.oneAssetRowContainer : stylesList.rowContainer}
			onClick={clickHandler}
			border
			hover
			data-test={dataTest}
		>
			<GridCell withoutBlur className={styles.icons}>
				{is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
				{is_mesh_network && <Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />}
			</GridCell>

			<GridCell dataTest="endpoint-table-item-endpoint-url-column">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{url}
				</TypoHighlighted>

				<Typo variant="D/Regular/Meta" color="secondary" className={styles.method}>
					{methodOrProtocol(endpoint)}
				</Typo>
			</GridCell>

			<GridCell dataTest="endpoint-table-item-host-column">
				<TypoHighlighted variant="D/Regular/Body-S" search={search}>
					{host}
				</TypoHighlighted>

				{process_name && (
					<Typo variant="D/Regular/Meta" color="secondary" className={styles.processName}>
						Process: {process_name}
					</Typo>
				)}
			</GridCell>

			<GridCell align="right" dataTest="endpoint-table-item-rps-column">
				<Typo variant="D/Regular/Meta" component="span">
					{toAbbreviatedNumber(estimated_requests)}
				</Typo>
				<Typo variant="D/Regular/Meta" component="span" className={styles.withLeftSeparator}>
					{toRPS(estimated_requests)}
				</Typo>
			</GridCell>

			{!oneAssetMode && (
				<GridCell dataTest="endpoint-table-item-namespace-column">
					<TypoHighlighted variant="D/Regular/Body-S" search={search}>
						{namespace || DASH_SIGN}
					</TypoHighlighted>
				</GridCell>
			)}

			{!oneAssetMode && (
				<GridCell dataTest="assets">
					<AssetChip
						classes={{ root: styles.assetNameRoot, label: styles.assetNameLabel }}
						noTooltip
						label={
							<>
								<TypoHighlighted variant="D/Medium/Meta" search={search}>
									{assetName}
								</TypoHighlighted>
							</>
						}
						isExternal={asset_is_external}
					/>
				</GridCell>
			)}

			<GridCell dataTest="endpoint-table-item-sensitivity-column">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="endpoint-table-item-pii-types-column">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="endpoint-table-item-last-date-column">
				<Tooltip title={lastSeenValue.date}>
					<div>{lastSeenValue.diff}</div>
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { EndpointItem };
