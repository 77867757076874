import cn from 'classnames';
import React, {
	createContext,
	KeyboardEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Typo from 'components/typography/Typo';
import { ColoredWrapper, ColoredWrapperState } from '../ColoredWrapper';
import { ActiveRule, Option } from '../PolicyBuilder';
import {
	AND_OPERATOR,
	getAndRule,
	getEmptyGroup,
	getOrRule,
	OR_OPERATOR,
	isEmptyRule,
} from '../PolicyBuilder/helpers.ts';
import { RuleItemArray } from '../RuleList';
import styles from './index.module.pcss';
import { RuleItem } from './RuleItem';

interface Props {
	activeRule: ActiveRule | null;
	dim: boolean;
	first: boolean;
	groupId: number;
	last: boolean;
	setNextGroupActive: (groupId?: number) => void;
	onClose: (() => void) | undefined;
	open: boolean;
	option: Option | undefined;
	onActiveRuleChange: (activeRule: ActiveRule) => void;
	onRuleChange: (ruleItem: RuleItemArray, activeRule: ActiveRule) => void;
	value: RuleItemArray;
}

export type EDIT_MODE = 'type' | 'values' | 'key' | null;
const SERVICE_SEPARATOR = '  ,';
const MAIN_INPUT_INDEX = -1;
const KEY_INPUT_INDEX = -2;

export const StateContext = createContext('unfocusedEmpty');

export function RuleGroup({
	activeRule,
	dim,
	first,
	groupId,
	last,
	setNextGroupActive,
	onClose,
	open,
	option,
	onActiveRuleChange,
	onRuleChange,
	value: ruleValue,
}: Props) {
	const [hoveredGroupId, setHoveredGroupId] = useState<number | null>(null);
	const [state, setState] = useState<ColoredWrapperState>('unfocusedEmpty');
	const [editMode, setEditMode] = useState<EDIT_MODE>(null);
	const [mainInputValue, setMainInputValue] = useState('');
	const [indexFocusedRule, setIndexFocusedRule] = useState<number | null>(null);

	const notEmpty = useMemo(() => {
		return ruleValue.type.length > 0 || mainInputValue.length > 0;
	}, [ruleValue.type, mainInputValue]);

	const [focusedGroup, setFocusedGroupId] = useState<ActiveRule['index'] | null>();

	const isLabel = useMemo(
		() => ruleValue.type === 'Namespace label' || ruleValue.type === 'Service label',
		[ruleValue]
	);

	useEffect(() => {
		const isFocusedGroup = activeRule?.index === ruleValue.index;
		if (isFocusedGroup) {
			setFocusedGroupId(focusedGroup);
			setMainInputValue(activeRule?.mainInputValue);

			if (option) {
				// TODO Maybe move to separate useEffect
				onChange(option.name, indexFocusedRule ?? activeRule?.ruleId);
				onEnter(option.name, indexFocusedRule ?? activeRule?.ruleId);
			} else {
				if (isEmptyRule(ruleValue)) {
					setEditMode('type');
				}

				if (indexFocusedRule === null) {
					setIndexFocusedRule(null); // TODO WHAT?
					setIndexFocusedRule(activeRule?.ruleId);
				}
			}
		} else {
			// When the rule is unfocused,
			// we deactivate editMode and update the status for ColoredWrapper accordingly
			// We cannot use here onBlur, because it close the builder
			setEditMode(null);
			setFocusedGroupId(null);
			setIndexFocusedRule(null);
			setMainInputValue('');
			setHoveredGroupId(null);

			// TODO: when mainInput isn't empty and we click to another group we have to send new data to the Rule list
			// Now there's a problem because onContainerClick send additional time data
			// Potential solution: divide sending ruleValue and activeRule for different handler
			// and in the onContainerClick send only activeRule data
			// saveLastServiceOnBlur();
		}
	}, [option, activeRule, ruleValue, isLabel]);

	// If we click inside the group,
	// we need to send group data to RuleList depending on the cursor position.
	useEffect(() => {
		if (editMode === 'type' || editMode === 'key') {
			onActiveRuleChange(prepareActiveRuleFromMainRule(mainInputValue));
		} else if (editMode === 'values') {
			onActiveRuleChange(prepareActiveRule(ruleValue, indexFocusedRule ?? MAIN_INPUT_INDEX));
		}
	}, [editMode, indexFocusedRule, ruleValue]);

	// Set state for ColoredWrapper
	useEffect(() => {
		switch (true) {
			case first && isEmptyRule(ruleValue):
				setState('unfocusedEmptyFirst');
				break;
			case hoveredGroupId === groupId:
				setState('unfocusedHoveredWithContent');
				break;
			case editMode === 'type' && mainInputValue.length === 0:
				setState('focusedEmpty');
				break;
			case (notEmpty || ruleValue.values.length > 0) && !editMode:
				setState('unfocusedWithContent');
				break;
			case notEmpty || editMode:
				setState('focusedWithContent');
				break;
			default:
				setState('unfocusedEmpty');
		}
	}, [ruleValue, notEmpty, editMode, mainInputValue, hoveredGroupId]);

	// If clickAway is occurred  we clean the state
	useEffect(() => {
		if (!open && activeRule) {
			onBlur();
		}
	}, [open, activeRule]);

	const onContainerClick = useCallback(
		(mode?: EDIT_MODE) => {
			// TODO
			if (mode && typeof mode === 'string') {
				setEditMode(mode);
			} else {
				if (ruleValue.type.length === 0) {
					setEditMode('type');
				} else {
					setEditMode('values');
				}
			}
		},
		[ruleValue]
	);

	const showCompensator = useMemo(() => {
		return !first && isEmptyRule(ruleValue) && !!editMode;
	}, [ruleValue, editMode]);

	function onEnter(_value: string, ruleId: number) {
		if (editMode === 'type' && _value.toUpperCase() === OR_OPERATOR) {
			sendGroupData(getOrRule(), ruleId);

			setNextGroupActive();
		} else if (editMode === 'type' && _value.toUpperCase() === AND_OPERATOR && dim) {
			sendGroupData(getAndRule(), ruleId);

			setNextGroupActive();
		} else if (editMode === 'values' && _value.toUpperCase() === 'ANY') {
			sendGroupData({ ...ruleValue, values: [] }, ruleId);

			setNextGroupActive();
		} else if (isLabel && editMode === 'values' && _value.toUpperCase() === 'NONE') {
			sendGroupData({ ...ruleValue, values: ['None'] }, ruleId);

			setNextGroupActive();
		} else if (ruleId === KEY_INPUT_INDEX) {
			if (_value === '') {
				sendGroupData(getEmptyGroup(), ruleId);
			} else {
				sendGroupData({ ...ruleValue, key: _value }, ruleId);
				setEditMode('values');
				setIndexFocusedRule(MAIN_INPUT_INDEX);
			}
		} else if (ruleId === MAIN_INPUT_INDEX) {
			if (_value === '') {
				if (editMode === 'type') {
					onBlur();
					onClose?.();
				} else {
					// Because no values was added we do focus to the next group
					sendGroupData(ruleValue, ruleId);
					setNextGroupActive();
				}
			} else {
				if (editMode === 'type' && (_value === 'Namespace label' || _value === 'Service label')) {
					setEditMode('key');
					setIndexFocusedRule(KEY_INPUT_INDEX);
				} else {
					if (editMode === 'type') {
						setEditMode('values');
					} else if (editMode === 'key') {
						sendGroupData({ ...ruleValue, key: _value }, ruleId);
						setEditMode('values');
					} else if (editMode === 'values') {
						sendGroupData({ ...ruleValue, values: [...ruleValue.values, _value] }, ruleId);
					}

					setIndexFocusedRule(MAIN_INPUT_INDEX);
				}
			}
		} else {
			setIndexFocusedRule(MAIN_INPUT_INDEX);
		}

		setMainInputValue('');
	}

	function saveLastServiceOnBlur() {
		if (
			editMode === 'values' &&
			indexFocusedRule === MAIN_INPUT_INDEX &&
			mainInputValue.length > 0
		) {
			sendGroupData(
				{ ...ruleValue, values: [...ruleValue.values, mainInputValue] },
				MAIN_INPUT_INDEX
			);
		}
	}

	function onBlur() {
		setIndexFocusedRule(null);
		setEditMode(null);
		setMainInputValue('');
		setHoveredGroupId(null);

		saveLastServiceOnBlur();
	}

	// This function is necessary if we're leaving component by `tab` pressing
	function setNewFocus(step: number, startIndex: number) {
		if (indexFocusedRule !== null) {
			if (startIndex === MAIN_INPUT_INDEX) {
				if (step === -1) {
					// Can move only to the left from the `mainInput`
					setIndexFocusedRule(ruleValue.values.length - 1);
				}
			} else {
				const newIndex = indexFocusedRule + step;

				if (newIndex > ruleValue.values.length - 1) {
					setIndexFocusedRule(MAIN_INPUT_INDEX);
				} else if (newIndex >= 0 && newIndex !== startIndex) {
					setIndexFocusedRule(indexFocusedRule + step);
				}
			}
		}
	}

	function onDelete(ruleId: number) {
		if (ruleId === KEY_INPUT_INDEX) {
			if (ruleValue.key.length === 0) {
				sendGroupData(getEmptyGroup(), ruleId);
			}
		} else if (ruleId === MAIN_INPUT_INDEX) {
			if (ruleValue.type.length === 0) {
				// If we try to delete last group we do nothing
				if (!last) {
					// If we delete empty group(someone between in other groups) we just close the builder
					onBlur();
					onClose?.();
				}
			} else if (isLabel && ruleValue.key.length === 0) {
				sendGroupData(getEmptyGroup(), ruleId);
			} else if (ruleValue.values.length === 0) {
				if (isLabel) {
					setIndexFocusedRule(KEY_INPUT_INDEX);
					setEditMode('key');
				} else {
					sendGroupData(getEmptyGroup(), ruleId);
				}
			} else {
				setNewFocus(-1, MAIN_INPUT_INDEX);
			}
		} else {
			const newRuleValue = {
				...ruleValue,
				values: [...ruleValue.values.slice(0, ruleId), ...ruleValue.values.slice(ruleId + 1)],
			};

			if (newRuleValue.values.length === 0) {
				setNewFocus(1, ruleId);
			} else {
				setNewFocus(-1, ruleId);
			}

			sendGroupData(newRuleValue, ruleId);
		}
	}

	function onWrapperHover(_groupId: number) {
		setHoveredGroupId(_groupId);
	}

	function onWrapperBlur() {
		setHoveredGroupId(null);
	}

	function prepareActiveRule(_rule: RuleItemArray, ruleId: number) {
		const _activeRule: ActiveRule = {
			groupId,
			index: _rule.index,
			key: _rule.key,
			mainInputValue,
			ruleId,
			type: _rule.type,
			value: _rule.values[ruleId] ?? mainInputValue,
		};

		return _activeRule;
	}

	function prepareActiveRuleFromMainRule(_mainInputValue: string) {
		const _activeRule: ActiveRule = {
			groupId,
			index: ruleValue.index,
			mainInputValue: _mainInputValue,
			ruleId: MAIN_INPUT_INDEX,
			type: ruleValue.type,
		};

		if (editMode === 'type') {
			_activeRule['type'] = _mainInputValue;
		} else if (editMode === 'key') {
			_activeRule['key'] = ruleValue.key;
		} else if (editMode === 'values') {
			_activeRule['type'] = ruleValue.type;
			_activeRule['value'] = _mainInputValue;
		}

		return _activeRule;
	}

	function sendGroupData(_rule: RuleItemArray, ruleId: number) {
		const _activeRule =
			editMode === 'key'
				? prepareActiveRuleFromMainRule(mainInputValue)
				: prepareActiveRule(_rule, ruleId);

		onRuleChange(_rule, _activeRule);
	}

	function sendGroupDataFromMainRule(_mainInputValue: string) {
		const newRuleValue = { ...ruleValue };

		if (editMode === 'type') {
			newRuleValue.type = _mainInputValue;
		} else if (editMode === 'key') {
			newRuleValue.key = _mainInputValue;
		}

		setMainInputValue(_mainInputValue);
		onRuleChange(newRuleValue, prepareActiveRuleFromMainRule(_mainInputValue));
	}

	function onChange(_value: string, ruleId: number) {
		if (ruleId === MAIN_INPUT_INDEX) {
			sendGroupDataFromMainRule(_value);
		} else if (ruleId === KEY_INPUT_INDEX) {
			sendGroupData({ ...ruleValue, key: _value, values: [] }, ruleId);
		} else {
			const newServices = [...ruleValue.values];
			newServices[ruleId] = _value;

			sendGroupData({ ...ruleValue, values: newServices }, ruleId);
		}
	}

	function onKeyDown(event: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) {
		if (
			event.code === 'Enter' ||
			(event.code === 'Comma' && editMode === 'values') ||
			(event.code === 'Semicolon' && editMode === 'type')
		) {
			event.preventDefault();

			onEnter(value, ruleId);
		} else if (event.code === 'ArrowLeft') {
			const target = event.target as HTMLInputElement; // Typescript trick to recognize `selectionEnd`

			if (target.selectionEnd === 0) {
				setNewFocus(-1, ruleId);
			}
		} else if (event.code === 'ArrowRight') {
			const target = event.target as HTMLInputElement; // Typescript trick to recognize `selectionEnd`

			if (target.selectionEnd === value.length) {
				setNewFocus(1, ruleId);
			}
		} else if (event.code === 'Backspace') {
			if (value.length === 0) {
				event.preventDefault();

				onDelete(ruleId);
			}
		} else if (event.code === 'Escape') {
			event.preventDefault();

			onEnter(value, ruleId);
			onBlur();
			onClose?.();
		}
	}

	return (
		<StateContext.Provider value={state}>
			{editMode !== 'type' && ruleValue.type && (
				<ColoredWrapper
					groupId={groupId}
					onClick={onContainerClick}
					onHover={() => onWrapperHover(groupId)}
					onBlur={onWrapperBlur}
				>
					<Typo
						className={cn({ [styles.active]: !!editMode })}
						component="span"
						onClick={() => setIndexFocusedRule(MAIN_INPUT_INDEX)}
						variant="D/Medium/Body-S"
						color="secondary"
					>
						{`${ruleValue.type}${(editMode === 'values' || !editMode) && !isLabel ? ':' : ''}`}
					</Typo>
				</ColoredWrapper>
			)}

			{isLabel && editMode !== 'type' && (
				<ColoredWrapper
					groupId={groupId}
					onClick={() => onContainerClick('key')}
					onHover={() => onWrapperHover(groupId)}
					onBlur={onWrapperBlur}
				>
					<Typo
						className={cn({ [styles.active]: editMode === 'key' })}
						component="span"
						variant="D/Medium/Body-S"
						color="secondary"
					>
						key:{' '}
					</Typo>
				</ColoredWrapper>
			)}

			{isLabel && editMode !== 'type' && (
				<ColoredWrapper
					groupId={groupId}
					onClick={() => onContainerClick('key')}
					onHover={() => onWrapperHover(groupId)}
					onBlur={onWrapperBlur}
				>
					<RuleItem
						editMode={editMode}
						focused={indexFocusedRule === KEY_INPUT_INDEX}
						onChange={onChange}
						onFocus={setIndexFocusedRule}
						onKeyDown={onKeyDown}
						ruleId={KEY_INPUT_INDEX}
						value={ruleValue.key}
					/>
				</ColoredWrapper>
			)}

			{isLabel && editMode !== 'type' && (
				<ColoredWrapper
					groupId={groupId}
					onClick={() => onContainerClick('values')}
					onHover={() => onWrapperHover(groupId)}
					onBlur={onWrapperBlur}
				>
					<Typo
						className={cn({ [styles.active]: editMode === 'values' })}
						component="span"
						onClick={() => setIndexFocusedRule(MAIN_INPUT_INDEX)}
						variant="D/Medium/Body-S"
						color="secondary"
					>
						value:{' '}
					</Typo>
				</ColoredWrapper>
			)}

			{editMode !== 'type' &&
				ruleValue.values.map((value, valueIndex) => (
					<ColoredWrapper
						groupId={groupId}
						key={valueIndex}
						onClick={onContainerClick}
						onHover={() => onWrapperHover(groupId)}
						onBlur={onWrapperBlur}
						valueMode
					>
						<RuleItem
							editMode={editMode}
							focused={indexFocusedRule === valueIndex}
							onChange={onChange}
							onFocus={setIndexFocusedRule}
							onKeyDown={onKeyDown}
							ruleId={valueIndex}
							value={value}
						/>
						{
							/* Display SERVICE_SEPARATOR after every rule, but only if the next rule is exist */
							ruleValue.values[valueIndex + 1] !== undefined ||
							/* When we're unfocused we shouldn't see last SERVICE_SEPARATOR */
							(ruleValue.values.length !== 0 && editMode === 'values')
								? SERVICE_SEPARATOR
								: null
						}
					</ColoredWrapper>
				))}

			{(!editMode && ruleValue.type.length !== 0 && ruleValue.values.length === 0) ||
			(isLabel && editMode === 'key' && ruleValue.values.length === 0) ? (
				<ColoredWrapper
					groupId={groupId}
					onClick={() => onContainerClick('values')}
					valueMode
					onHover={() => onWrapperHover(groupId)}
					onBlur={onWrapperBlur}
				>
					Any
				</ColoredWrapper>
			) : null}

			{(notEmpty && !editMode) || editMode === 'key' ? null : (
				<>
					{/* MainInput */}
					<ColoredWrapper
						groupId={groupId}
						onClick={onContainerClick}
						valueMode={editMode === 'values'}
					>
						<RuleItem
							editMode={editMode}
							first={first && !notEmpty}
							focused={indexFocusedRule === MAIN_INPUT_INDEX}
							focusRefresher={ruleValue.type + ruleValue.values.length + mainInputValue}
							onChange={onChange}
							onFocus={setIndexFocusedRule}
							onKeyDown={onKeyDown}
							ruleId={MAIN_INPUT_INDEX}
							value={mainInputValue}
						/>
					</ColoredWrapper>
				</>
			)}

			{showCompensator && (
				<div
					className={cn(styles.rightCompensator, {
						[styles.focused]: notEmpty,
					})}
				/>
			)}
		</StateContext.Provider>
	);
}
