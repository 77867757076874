import cn from 'classnames';
import { ReactNode } from 'react';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	title: string;
	children: ReactNode;
	description?: string;
	hrBottom?: boolean;
	topRightBlock?: ReactNode;
	className?: string;
};

export const PolicyGroupFields = (props: Props) => {
	const { title, description, hrBottom, children, className, topRightBlock } = props;

	return (
		<div className={cn(styles.container, className)}>
			<div className={styles.header}>
				<Typo variant="D/Medium/H100-Header">{title}</Typo>
				{topRightBlock}
			</div>
			{description && <Typo variant="D/Regular/Body-S">{description}</Typo>}

			<div className={description ? styles.contentWithDescription : styles.content}>{children}</div>

			{hrBottom && <div className={styles.horizontalLine} />}
		</div>
	);
};
