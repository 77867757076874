import cn from 'classnames';
import { useEffect, useState } from 'react';
import Preloader from 'components/Preloader';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getRecentActivityFx } from 'models/recentActivity/effects';
import { getReleaseFx } from 'models/release/effects';
import { PATHS } from 'services/router';
import { ReleaseUpdateAlert } from 'views/common/ReleaseUpdateAlert';
import { APICalls } from './APICalls';
import { DataDistributionByRegions } from './DataDistributionByRegions';
import { DataStoresOverview } from './DataStoresOverview';
import { DataTypesOverview } from './DataTypesOverview';
import { HighPriorityEvents } from './HighPriorityEvents';
import styles from './index.module.css';
import { MajorPolicyViolations } from './MajorPolicyViolations';
import { PolicyEventsByCategory } from './PolicyEventsByCategory';
import { WidgetContainer } from './WidgetContainer';

function Dashboard() {
	const [isLoading, setLoading] = useState(true);
	const [eventsCount, setEventsCount] = useState<number>(0);

	useEffect(() => {
		Promise.all([getRecentActivityFx(), getReleaseFx()]).then(() => {
			setLoading(false);
		});
	}, []);

	return (
		<>
			<Header>
				<Header.Breadcrumbs hideHome />
				<Header.Title>Dashboard</Header.Title>
			</Header>

			<Preloader isLoading={isLoading}>
				<ReleaseUpdateAlert type="dim" />
				<ReleaseUpdateAlert type="dar" />

				<div className={cn(styles.twoWidgets, styles.marginTop, styles.minRightWidgetSize)}>
					<WidgetContainer title="API calls">
						<APICalls />
					</WidgetContainer>

					<WidgetContainer
						flex={null}
						title="High-priority events"
						counter={eventsCount}
						seeAllLink={PATHS.EVENTS}
					>
						<HighPriorityEvents setEventsCount={setEventsCount} />
					</WidgetContainer>
				</div>

				<Typo variant="D/Medium/H100-Header" className={styles.title}>
					Inventory
				</Typo>

				<div className={cn(styles.twoWidgets, styles.marginBottom)}>
					<WidgetContainer title="Data types" seeAllLink={PATHS.DATA_TYPES_LIST}>
						<DataTypesOverview />
					</WidgetContainer>

					<WidgetContainer title="Data distribution by regions">
						<DataDistributionByRegions />
					</WidgetContainer>
				</div>

				<WidgetContainer title="Data stores" seeAllLink={PATHS.DATA_STORAGES} noPadding>
					<DataStoresOverview />
				</WidgetContainer>

				<Typo variant="D/Medium/H100-Header" className={styles.title}>
					Compliance
				</Typo>

				<div className={cn(styles.twoWidgets, styles.paddingBottom)}>
					<WidgetContainer
						title="Policy events"
						seeAllLink={`${PATHS.EVENTS}?type=policy_violations`}
					>
						<PolicyEventsByCategory />
					</WidgetContainer>

					<WidgetContainer
						title="Major policy violations"
						seeAllLink={`${PATHS.EVENTS}?type=policy_violations`}
					>
						<MajorPolicyViolations />
					</WidgetContainer>
				</div>
			</Preloader>
		</>
	);
}

export { Dashboard };
