import cn from 'classnames';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import Icon from 'components/Icon';
import { Operator } from '../Operator';
import { ActiveRule, Option } from '../PolicyBuilder';
import {
	AND_OPERATOR,
	OR_OPERATOR,
	getEmptyGroup,
	isEmptyRule,
	isOperator,
} from '../PolicyBuilder/helpers.ts';
import { RuleGroup } from '../RuleGroup';
import styles from './index.module.css';

interface Props {
	activeRule: ActiveRule | null;
	dim: boolean;
	onActiveRuleChange: (activeRule: ActiveRule | null) => void;
	onClick: (event: MouseEvent<HTMLElement>) => void;
	onClose: () => void;
	open: boolean;
	option: Option | undefined;
	onRulesChange: (rules: RuleItemArray[]) => void;
	rules: RuleItemArray[];
}

export interface RuleItemProps {
	key: string;
	operator: typeof AND_OPERATOR | typeof OR_OPERATOR | null;
	type: string;
	values: string[];
}

export interface RuleItemArray extends RuleItemProps {
	index: number;
}

export function RuleList(props: Props) {
	const { onClick, onActiveRuleChange, open, rules, onClose, option, onRulesChange, dim } = props;
	const [activeRule, setActiveRule] = useState<ActiveRule | null>(null);
	const [activeOperator, setActiveOperator] = useState<RuleItemArray | null>(null);
	const ruleListContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClick = (event: KeyboardEvent) => {
			if (activeOperator !== null && event.code === 'Backspace' && activeRule) {
				onChange(activeOperator, activeRule);
				setActiveOperator(null);
			}
		};

		document.addEventListener('keydown', handleClick);

		return () => {
			document.removeEventListener('keydown', handleClick);
		};
	}, [activeOperator, ruleListContainerRef]);

	// When clickAway is occurred we clean the state
	useEffect(() => {
		if (!open) {
			setActiveRule(null);
			setActiveOperator(null);
		}
	}, [open]);

	function onOperatorClick(rule: RuleItemArray, groupId: number) {
		setActiveOperator(rule);

		setActiveRule({
			type: rule.type,
			groupId,
			index: rule.index,
			mainInputValue: '',
			ruleId: -1,
		});
	}

	function onChange(rule: RuleItemArray, _activeRule: ActiveRule) {
		const _groupId = _activeRule.groupId;
		if (_groupId === rules.length - 1) {
			// If we're on the last rule, and we delete all values, we update all rules for additional rendering
			if (isEmptyRule(rule)) {
				onRulesChange([...rules]);
				setActiveRule(_activeRule);
			} else {
				// If this rule is the only one
				if (rules.length === 1) {
					const newRules = [...rules];
					newRules.splice(_groupId, 1, rule, getEmptyGroup());

					onRulesChange(newRules);
					setActiveRule({ ..._activeRule, groupId: _activeRule.groupId + 1 });
				} else {
					const newRules = [...rules];
					newRules.splice(_groupId, 1, getEmptyGroup(), rule, getEmptyGroup());

					onRulesChange(newRules);
					setActiveRule({ ..._activeRule, groupId: _activeRule.groupId + 1 });
				}
			}
		} else {
			if (isEmptyRule(rules[_groupId])) {
				if (!isEmptyRule(rule)) {
					const newRules = [...rules];
					newRules.splice(_groupId, 1, getEmptyGroup(), rule, getEmptyGroup());

					onRulesChange(newRules);
					setActiveRule({ ..._activeRule, groupId: _activeRule.groupId + 1 });
				} else {
					setActiveRule(_activeRule);
				}
			} else {
				// We send emptyRule if we want to delete rule
				if (isEmptyRule(rule) || isOperator(rules[_groupId])) {
					let newRules = [...rules];

					// When the rule list is empty, and we start to type, another group is created.
					// And when the first group is deleted with Backspace key, we have to use created group to focus it.
					// If we create new list with [getEmptyGroup()], setNextGroupActive cannot find new group
					if (rules.length === 2) {
						newRules = [newRules[1]];
					} else {
						// _groupId - 1 < 0 - if rule has _groupId === 0, then splice will remove from the end, that's wrong behavior
						// count 2 - because we delete previous emptyRule
						// newRules.splice(_groupId - 1 < 0 ? 0 : _groupId - 1, 2);
						newRules.splice(_groupId - 1 < 0 ? 0 : _groupId - 1, 2);
					}

					onRulesChange(newRules);
					setNextGroupActive();
				} else {
					const newRules = [...rules];
					newRules[_groupId] = rule;

					onRulesChange(newRules);
					setActiveRule(_activeRule);
				}
			}
		}
	}

	function onContainerClick(e: MouseEvent<HTMLDivElement>) {
		e.stopPropagation();
		e.preventDefault();

		if (e.target instanceof HTMLElement && !e.target.closest('[data-group]')) {
			setNextGroupActive(rules.length - 1);
		}

		onClick(e);
	}

	function setNextGroupActive(_groupId?: ActiveRule['groupId']) {
		const activeGroupId =
			_groupId !== undefined && rules[_groupId]
				? _groupId
				: activeRule && rules[activeRule?.groupId + 1]
				? activeRule?.groupId + 1
				: rules.length - 1;
		const activeGroup = rules[activeGroupId];

		const newActiveRule: ActiveRule = {
			...activeGroup,
			mainInputValue: '',
			groupId: activeGroupId,
			ruleId: -1,
		};

		setActiveRule(newActiveRule);
	}

	useEffect(() => {
		onActiveRuleChange(activeRule);
	}, [activeRule]);

	return (
		<div
			id="rule-list"
			className={cn(styles.container, { [styles.active]: open })}
			onClick={onContainerClick}
			ref={ruleListContainerRef}
		>
			<Icon name="search" size={20} className={styles.searchIcon} />

			<div className={styles.rulesContainer}>
				{rules.map((rule, groupId) => {
					if (rule.operator) {
						return (
							<Operator
								activeRule={activeRule}
								groupId={groupId}
								key={`operator${rule.index}`}
								onClick={onOperatorClick}
								value={rule}
							/>
						);
					}

					return (
						<RuleGroup
							activeRule={activeRule}
							dim={dim}
							first={rules.length === 1}
							groupId={groupId}
							key={`rule${rule.index}`}
							last={rules.length - 1 === groupId}
							open={open}
							option={option}
							onActiveRuleChange={setActiveRule}
							onClose={onClose}
							onRuleChange={onChange}
							setNextGroupActive={setNextGroupActive}
							value={rule}
						/>
					);
				})}
			</div>
		</div>
	);
}
