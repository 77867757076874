const LOCALE = 'en-US';
const SECONDS_IN_DAY = 24 * 60 * 60;

function toLocaleString(number?: number) {
	return (number || 0).toLocaleString(LOCALE);
}

function toFileSize(bytes: number, decimals = 2) {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function toAbbreviatedNumber(number: number, decimals = 0) {
	if (!+number) return '0';

	const k = 1000;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['', 'k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

	const i = Math.floor(Math.log10(number) / 3);
	return `${parseFloat(
		(number / Math.pow(k, i)).toLocaleString(LOCALE, {
			minimumFractionDigits: dm,
			maximumFractionDigits: dm,
		})
	)}${sizes[i]}`;
}

function toFixedOrLessThan(number: number, decimals = 2, threshold = 0.01) {
	return number < threshold
		? `<${threshold}`
		: number.toLocaleString(LOCALE, {
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
		  });
}

function toRPS(number: number) {
	return toFixedOrLessThan((number || 0) / SECONDS_IN_DAY);
}

export { toFileSize, toAbbreviatedNumber, toLocaleString, toRPS };
