import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Badge from 'components/Badge';
import { CurtainOverlay } from 'components/Curtain';
import Loader from 'components/Loader';
import { PolicyBuilder } from 'components/ResourceField/PolicyBuilder';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { getDatabaseList } from 'models/databases/api';
import { dataTypesPartsByIds } from 'models/dataTypes/helpers';
import { getKafkaTopicList } from 'models/kafkaTopics/api';
import { getNoSQLDatabaseList } from 'models/noSQLDatabases/api';
import { DARPolicyItem } from 'models/policiesV2/dto';
import {
	createPolicyFx,
	deletePolicyFx,
	togglePolicyTrackingFx,
	updatePolicyFx,
} from 'models/policiesV2/effects';
import { getS3Buckets } from 'models/s3Buckets/api';
import { APIError } from 'services/api/httpRequest';
import { getPreviousPath } from 'services/history';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { pluralize } from 'services/strings';
import { LS_POLICY_DUPLICATE_KEY } from '..';
import { DeleteModal } from '../DeleteModal';
import styles from './index.module.css';
import { PolicyAlert, usePolicyAlert } from './PolicyAlert';
import { PolicyDataTypesV2 } from './PolicyDataTypesV2';
import { PolicyDescription } from './PolicyDescription';
import { PolicyFormFooter } from './PolicyFormFooter';
import { PolicyFormHeader } from './PolicyFormHeader';
import { PolicyGroupFields } from './PolicyGroupFields';
import { PolicyName } from './PolicyName';
import { ResourceConfigurations } from './ResourceConfigurations';
import { RuleCurtain, RuleCurtainProps } from './RuleCurtain';
import { Tags } from './Tags';

type Props = {
	data: DARPolicyItem;
};

const ALREADY_EXIST_REGEXP = /name/;
const DATA_TYPES_CANNOT_BE_EMPTY_REGEXP = /Data types/;

export type LocationItem = { id: string | number; name: string; dataTypes: number[] };
export type DARLocations = {
	sqlDatabases: LocationItem[];
	nosqlDatabases: LocationItem[];
	s3buckets: LocationItem[];
	kafkaTopics: LocationItem[];
};

export const PolicyItemFormDAR = ({ data }: Props) => {
	const [isAlertShown, hideAlert] = usePolicyAlert();
	const [displayRuleCurtain, setDisplayRuleCurtain] = useState(false);
	const [formData, setFormData] = useImmer(data);
	const [policyNameError, setPolicyNameError] = useState('');
	const [locations, setLocations] = useState<DARLocations | null>(null);

	const dataTypesParts = useMemo(() => {
		return dataTypesPartsByIds(formData.data_types);
	}, [formData.data_types]);

	useEffect(() => {
		function simplifyData(
			darItems: { id: string | number; name: string; data_types: number[] }[]
		): LocationItem[] {
			return darItems
				.map((darItem) => ({
					id: darItem.id,
					name: darItem.name,
					dataTypes: darItem.data_types,
				}))
				.sort((a, b) => a.name.localeCompare(b.name))
				.filter((darItem, i, arr) => {
					const previousItem = arr[i - 1];
					if (!previousItem) return true;

					return darItem.name !== previousItem.name;
				});
		}

		Promise.all([
			getDatabaseList({ limit: 1000, offset: 0 }),
			getNoSQLDatabaseList({ limit: 1000, offset: 0 }),
			getS3Buckets({ limit: 1000, offset: 0 }),
			getKafkaTopicList({ limit: 1000, offset: 0 }),
		])
			.then(([sqlDb, nosqlDb, buckets, topics]) => {
				return {
					sqlDatabases: sqlDb.databases,
					nosqlDatabases: nosqlDb.databases,
					s3buckets: buckets.buckets,
					kafkaTopics: topics.topics,
				};
			})
			.then((darDataRaw) => {
				const darData = {
					sqlDatabases: simplifyData(darDataRaw.sqlDatabases),
					nosqlDatabases: simplifyData(darDataRaw.nosqlDatabases),
					s3buckets: simplifyData(darDataRaw.s3buckets),
					kafkaTopics: simplifyData(darDataRaw.kafkaTopics),
				};

				setLocations(darData);
			})
			.catch((e) => {
				console.error(e);
				enqueueSnackbar('Something went wrong when fetching locations');
			});
	}, []);

	const foundLocations: RuleCurtainProps['namespaces'] = useMemo(() => {
		if (locations === null) return [];

		const dataTypesSelected = dataTypesParts.dataTypesSource.map(({ id }) => id);

		function filterByDatatypes(darItems: LocationItem[]) {
			return darItems.filter((darItem) =>
				darItem.dataTypes.some((dtId) => dataTypesSelected.includes(dtId))
			);
		}

		return [
			{
				name: 'SQL databases',
				assets: filterByDatatypes(locations.sqlDatabases),
			},
			{
				name: 'NoSQL databases',
				assets: filterByDatatypes(locations.nosqlDatabases),
			},
			{
				name: 'S3 buckets',
				assets: filterByDatatypes(locations.s3buckets),
			},
			{
				name: 'Kafka topics',
				assets: filterByDatatypes(locations.kafkaTopics),
			},
		].filter((item) => item.assets.length > 0);
	}, [locations, dataTypesParts]);

	const topRef = useRef<HTMLDivElement>(null);
	const detailsSectionRef = useRef<HTMLDivElement>(null);
	const dataTypesSectionRef = useRef<HTMLDivElement>(null);
	const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		setFormData(data);
	}, [data]);

	const history = useHistory();

	const isNew = useMemo(() => formData.id === 0, [data.id]);

	const addRules = useCallback((rules) => {
		alert();
		// TODO
		setFormData((draft) => {
			draft.rules.push(...rules);
		});
	}, []);

	const setActive = useCallback((e) => {
		setFormData((draft) => {
			draft.is_active = e.target.checked;
		});
	}, []);

	const savePolicy = async () => {
		if (formData.name.length > 255) {
			enqueueSnackbar('Maximum Name length is 255');
			return;
		}

		const saveHandler = isNew ? createPolicyFx : updatePolicyFx;

		try {
			await saveHandler({
				...formData,
				data_types: [...dataTypesParts.groups, ...dataTypesParts.outOfGroup].map(({ id }) => id),
			});
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();

				switch (true) {
					case ALREADY_EXIST_REGEXP.test(message):
						scrollToTop(detailsSectionRef);
						setPolicyNameError(message);
						break;

					case DATA_TYPES_CANNOT_BE_EMPTY_REGEXP.test(message):
						scrollToTop(dataTypesSectionRef);
						enqueueSnackbar(message);
						break;

					default:
						scrollToTop(topRef);
						enqueueSnackbar(message);
				}

				return;
			}

			throw error;
		}

		history.push(PATHS.POLICY_LIST);
		enqueueSnackbar('Policy has been saved');
	};

	const deletePolicy = async () => {
		await DeleteModal({
			policyName: data.name,
			onConfirm: async () => {
				try {
					await deletePolicyFx(formData.id);
					enqueueSnackbar('Policy has been deleted');

					if (getPreviousPath() === PATHS.POLICY_LIST) {
						history.goBack();
					} else {
						history.replace(PATHS.POLICY_LIST);
					}
				} catch (error) {
					if (error instanceof APIError) {
						const { message } = await error.response.json();

						enqueueSnackbar(message);
					}
				}
			},
		});
	};

	const switchTracking = async () => {
		setFormData((draft) => {
			draft.is_active = !draft.is_active;

			togglePolicyTrackingFx({ id: draft.id, is_active: draft.is_active }).catch(() => {
				enqueueSnackbar('Something went wrong');
			});
		});
	};

	const duplicatePolicy = useCallback(() => {
		localStorage.setItem(LS_POLICY_DUPLICATE_KEY, JSON.stringify(formData));
	}, [formData]);

	return (
		<div className={styles.container} ref={topRef}>
			<PolicyFormHeader
				isNew={isNew}
				type={formData.type}
				deletePolicy={deletePolicy}
				duplicatePolicy={duplicatePolicy}
				tracking={formData.is_active}
				switchTracking={switchTracking}
				name={data.name}
			/>

			<PolicyGroupFields title="Details" hrBottom>
				<div className={styles.name}>
					{/* @ts-ignore */}
					<PolicyName value={formData.name} setFormData={setFormData} />
				</div>
				<div className={styles.description}>
					{/* @ts-ignore */}
					<PolicyDescription value={formData.description} setFormData={setFormData} />
				</div>
				{/* @ts-ignore */}
				<Tags value={formData.tags} options={formData.tags} setFormData={setFormData} />
			</PolicyGroupFields>

			<PolicyGroupFields
				title="Data"
				description="Define which data types and their location the policy will apply to."
				hrBottom
			>
				<div className={styles.dataTypesSubheader}>
					<Typo variant="D/SemiBold/Body-S">Types</Typo>

					<Typo
						dataTest="policy-locations-count"
						variant="D/Medium/Body-S"
						className={cn(styles.namespaceFound, foundLocations.length === 10 && styles.disabled)}
						onClick={() => setDisplayRuleCurtain(!displayRuleCurtain)}
					>
						Found in <Badge badgeContent={toLocaleString(foundLocations.length)} />{' '}
						{pluralize('location', foundLocations.length)}
					</Typo>
				</div>
				{/* @ts-ignore */}
				<PolicyDataTypesV2 value={formData.data_types} setFormData={setFormData} />
				<div className={styles.resourcesSubheader}>
					<Typo variant="D/SemiBold/Body-S">Resources</Typo>
				</div>

				{locations === null ? (
					<Loader />
				) : (
					<PolicyBuilder
						defaultRules={formData.rules}
						/* @ts-ignore */
						locations={locations}
						/* @ts-ignore */
						setFormData={setFormData}
						dim={false}
					/>
				)}
			</PolicyGroupFields>

			<PolicyGroupFields title="Resource Configurations" hrBottom={false}>
				<ResourceConfigurations
					allow_unencrypted_s3_buckets={formData.allow_unencrypted_s3_buckets}
					allow_public_s3_buckets={formData.allow_public_s3_buckets}
					allow_unencrypted_rds={formData.allow_unencrypted_rds}
					setFormData={setFormData}
				/>

				{isAlertShown && (
					<PolicyAlert
						header="Only new traffic will be tracked"
						onClose={hideAlert}
						className={styles.alert}
					>
						After saving, only future API requests will be checked for violations; past requests
						will not be evaluated.
					</PolicyAlert>
				)}
			</PolicyGroupFields>

			<PolicyFormFooter
				isNew={isNew}
				isError={!!policyNameError}
				saveForm={savePolicy}
				isActive={formData.is_active}
				setActive={setActive}
			/>

			<CurtainOverlay
				open={displayRuleCurtain}
				onClose={() => setDisplayRuleCurtain(false)}
				rightPart={
					<RuleCurtain
						type="dar"
						namespaces={foundLocations}
						onChange={(rules) => {
							addRules(rules);
							setDisplayRuleCurtain(false);
						}}
					/>
				}
				classes={{ hideButton: styles.hideButton }}
			/>
		</div>
	);
};
