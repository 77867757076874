import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { onceFx } from 'models/modelUtils/onceFx';
import { PolicyItem } from 'models/policiesV2/dto';
import { getPolicyListFx } from 'models/policiesV2/effects';
import { policiesList } from 'models/policiesV2/store';
import { FilterPropsBase } from '../model';

type Option = { id: PolicyItem['tags'][0]; name: PolicyItem['tags'][0] };

type Props = FilterPropsBase & {
	value: PolicyItem['tags'];
	onChange: (newValue: PolicyItem['tags']) => void;
	excludedAssetTypes?: boolean;
};

function TagFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const policies = useStore(policiesList);

	useEffect(() => {
		onceFx(getPolicyListFx);
	}, []);

	const groupedValues = useMemo(() => {
		const tags = [...new Set(policies.flatMap((policy) => policy.tags))].sort((a, b) =>
			a.localeCompare(b)
		);

		return tags.map((tag) => ({ id: tag, name: tag }));
	}, [policies]);

	const values = useMemo(() => {
		return groupedValues.filter((val) => value.includes(val.name));
	}, [value, groupedValues]);

	function handleChange(options: Option[]) {
		onChange(options.map((option) => option.name));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Tags', secondary: `${values.length || ''}` }}
			options={groupedValues}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default TagFilter;
