import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Checkbox from 'components/Checkbox';
import Chip from 'components/Chip';
import Button from 'components/form/Button';
import Typo from 'components/typography/Typo';
import { dataTypesById as dataTypesByIdStore } from 'models/dataTypes/store';
import { PolicyItem, DIMPolicyRuleItem, DARPolicyRuleItem } from 'models/policiesV2/dto';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.css';

type PolicyRuleList = (DIMPolicyRuleItem | DARPolicyRuleItem)[][];

type RuleCurtainProps = {
	type: PolicyItem['type'];
	namespaces: {
		name: string;
		assets: {
			id: string | number;
			name: string;
			subtitle?: string;
			checked?: boolean;
			dataTypes: number[];
		}[];
	}[];
	onChange: (rules: PolicyRuleList) => void;
};

function RuleCurtain(props: RuleCurtainProps) {
	const { namespaces, onChange } = props;

	const dataTypesById = useStore(dataTypesByIdStore);

	const [formData, setFormData] = useImmer(namespaces);

	useEffect(() => {
		setFormData(namespaces);
	}, [namespaces]);

	const checkAsset = useCallback((namespaceIndex, assetIndex) => {
		setFormData((draft) => {
			draft[namespaceIndex].assets[assetIndex].checked =
				!draft[namespaceIndex].assets[assetIndex].checked;
		});
	}, []);

	const checkAllAssetsInNamespace = useCallback((namespaceIndex, isChecked) => {
		setFormData((draft) => {
			draft[namespaceIndex].assets.forEach((asset) => {
				asset.checked = !isChecked;
			});
		});
	}, []);

	const selectAll = useCallback(() => {
		setFormData((draft) => {
			const isAllSelected = draft.every(({ assets }) => assets.every(({ checked }) => checked));

			draft.forEach(({ assets }) => assets.forEach((asset) => (asset.checked = !isAllSelected)));
		});
	}, []);

	const addRulesToDIMPolicy = useCallback(() => {
		const addedRules = formData.reduce((acc, namespace) => {
			const isAllAssetsSelected = namespace.assets.every(({ checked }) => checked);

			if (isAllAssetsSelected) {
				acc.push([
					{
						type: 'namespace',
						operator: 'is',
						key: '',
						values: [namespace.name],
					},
				]);
			} else {
				const assetValues = namespace.assets
					.filter(({ checked }) => checked)
					.map(({ name }) => name);

				if (assetValues.length > 0) {
					acc.push([
						{ type: 'namespace', operator: 'is', key: '', values: [namespace.name] },
						{
							type: 'asset',
							operator: 'is',
							key: '',
							values: assetValues,
						},
					]);
				}
			}

			return acc;
		}, [] as PolicyRuleList);

		onChange(addedRules);
	}, [formData]);

	const addRulesToDARPolicy = useCallback(() => {
		const addedRules = formData.reduce((acc, namespace) => {
			let darType: DARPolicyRuleItem['type'];

			switch (namespace.name) {
				case 'SQL databases':
					darType = 'sql_database';
					break;
				case 'NoSQL databases':
					darType = 'nosql_database';
					break;
				case 'S3 buckets':
					darType = 's3_bucket';
					break;
				case 'Kafka topics':
					darType = 'kafka_topic';
					break;

				default:
					console.error(`wrong dar type ${namespace.name}`);
					throw new Error();
			}

			const assetValues = namespace.assets.filter(({ checked }) => checked).map(({ name }) => name);

			if (assetValues.length > 0) {
				acc.push([
					{
						type: darType,
						operator: 'is',
						key: '',
						values: assetValues,
					},
				]);
			}

			return acc;
		}, [] as PolicyRuleList);

		onChange(addedRules);
	}, [formData]);

	let addRulesToPolicy;
	switch (props.type) {
		case 'dim':
			addRulesToPolicy = addRulesToDIMPolicy;
			break;
		case 'dar':
			addRulesToPolicy = addRulesToDARPolicy;
			break;

		default:
			console.error(`wrong dar type ${props.type}`);
			throw new Error();
	}

	const [totalAssets, selectedAssets] = useMemo(() => {
		return formData.reduce(
			(acc, namespace) => {
				return [
					acc[0] + namespace.assets.length,
					acc[1] + namespace.assets.filter((asset) => !!asset.checked).length,
				];
			},
			[0, 0] as [number, number]
		);
	}, [formData]);

	return (
		<div className={styles.container}>
			<Typo variant="D/Medium/H100-Header" className={styles.header}>
				Selected data type services
			</Typo>

			<Checkbox
				checked={selectedAssets > 0}
				halfPressed={selectedAssets !== totalAssets}
				onChange={selectAll}
				label={
					<>
						<Typo variant="D/Medium/Body-S" className={styles.namespaceTitle}>
							Select all
						</Typo>

						<Typo variant="D/Regular/Body-S" color="secondary">
							{totalAssets}
						</Typo>
					</>
				}
				className={styles.selectAll}
				data-test="policy-curtain-select-all"
			/>

			<div className={styles.content}>
				{formData.map(({ name, assets }, namespaceIndex) => {
					const assetsCheckedAll = assets.length > 0 && assets.every(({ checked }) => checked);
					const assetsCheckedPartial = assets.length > 0 && assets.some(({ checked }) => checked);

					return (
						<Accordion
							key={namespaceIndex}
							isDivider={false}
							defaultExpanded
							classes={{
								root: styles.accordion,
							}}
						>
							<AccordionSummary classes={{ content: styles.accordionTitle }}>
								<Checkbox
									dataTest="policy-curtain-namespace-checkbox"
									checked={assetsCheckedAll || assetsCheckedPartial}
									halfPressed={!assetsCheckedAll}
									onChange={() => {
										checkAllAssetsInNamespace(namespaceIndex, assetsCheckedAll);
									}}
									label={
										<>
											<Typo variant="D/Medium/Body-S" className={styles.namespaceTitle}>
												{name}
											</Typo>

											<Typo variant="D/Regular/Body-S" color="secondary">
												{toLocaleString(assets.length)}
											</Typo>
										</>
									}
								/>
							</AccordionSummary>

							<AccordionDetails classes={{ root: styles.accordionDetails }}>
								{assets.map((asset, assetIndex) => (
									<div className={styles.assetBlock} key={`${assetIndex}-${asset.checked}`}>
										<Checkbox
											dataTest="policy-curtain-asset-checkbox"
											checked={asset.checked}
											onChange={() => checkAsset(namespaceIndex, assetIndex)}
											className={styles.assetCheckbox}
											label={<Typo variant="D/Medium/Body-S">{asset.name}</Typo>}
										/>

										{asset.subtitle && (
											<Typo variant="D/Regular/Meta" color="secondary" className={styles.subtitle}>
												{asset.subtitle}
											</Typo>
										)}

										<div className={styles.selectedDataTypes}>
											{asset.dataTypes.map((dtId) => {
												return (
													<Chip
														key={dtId}
														theme="neutral"
														color="secondary"
														label={dataTypesById[dtId].name}
														size="extraSmall"
													/>
												);
											})}
										</div>
									</div>
								))}
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>

			<div className={styles.footer}>
				<Button
					data-test="policy-curtain-add-button"
					onClick={addRulesToPolicy}
					color="primary"
					size="small"
					disabled={namespaces.length === 0}
				>
					Add to the policy
				</Button>

				<Typo variant="D/Regular/Meta" color="secondary">
					You can always edit
					<br />
					these rules later.
				</Typo>
			</div>
		</div>
	);
}

export type { RuleCurtainProps };
export { RuleCurtain };
