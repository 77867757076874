import { useStore } from 'effector-react';
import { useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import FilterLine from 'components/FilterLine';
import Button from 'components/form/Button';
import Select from 'components/form/Select';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import Icon from 'components/Icon';
import ResetFilters from 'components/ResetFilters';
import { enqueueSnackbar } from 'components/Snackbar';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { dataTypesPartsByIds } from 'models/dataTypes/helpers';
import { PolicyItem } from 'models/policiesV2/dto';
import { generatePoliciesFx } from 'models/policiesV2/effects';
import { policyListExample } from 'models/policiesV2/examples';
import { policiesLoaded, policiesList } from 'models/policiesV2/store';
import { APIError } from 'services/api/httpRequest';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { PATHS } from 'services/router';
import CongratulationModal from './CongratulationModal';
import styles from './index.module.css';
import NoPoliciesBlock from './NoPoliciesBlock';
import { PolicyTable } from './PolicyTable';

const pageConfig = {
	type: {
		type: 'string',
		persistence: 'session',
	},
	tags: {
		type: 'stringArray',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Filter = {
	type: PolicyItem['type'] | '';
	tags: PolicyItem['tags'];
	'data-types': number[]; // TODO
};

const initialFilter: Filter = {
	type: '',
	tags: [],
	'data-types': [] as number[], // TODO
};

function DropdownButton(props: DropdownButtonProps) {
	const { onClick, children } = props;

	return (
		<div className={styles.dropdownButtonWrapper}>
			<Button
				color="secondary"
				onClick={onClick}
				data-test="spreadsheet-page-select"
				size="extraSmall"
				className={styles.spreadsheetSelectButton}
				startIcon={<Icon name="Add/Regular" size={20} />}
			>
				<span className={styles.newPolicyButton}>{children}</span>
			</Button>
		</div>
	);
}

const DIM_ITEM = 'Data in motion';
const DAR_ITEM = 'Data at rest';

function PolicyList() {
	const history = useHistory();
	const loaded = useStore(policiesLoaded);
	let policies = useStore(policiesList);
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'policies');

	const [generationLoading, setGenerationLoading] = useState(false);

	let skeletonMode = false;
	const isPolicies = policies.length > 0;
	console.log(policies);

	if (loaded && !isPolicies) {
		skeletonMode = true;
		policies = policyListExample.policies;
	}

	const sortedAndFiltered = useMemo(() => {
		// Sort
		let result = [...policies].sort(function (a, b) {
			return a.created_at - b.created_at;
		});

		// Then filter by type...
		const { type } = pageParams;
		result = !type ? result : result.filter((policy) => policy.type === type);

		// ...tag...
		console.log(pageParams);
		const { tags } = pageParams;
		result =
			tags.length === 0
				? result
				: result.filter((policy) => tags.some((tag) => policy.tags.includes(tag)));

		// ...and data type.
		const dataTypes = pageParams['data-types'];
		const dataTypeParts = dataTypesPartsByIds(dataTypes);
		const dataTypesFilter = [...dataTypeParts.groups, ...dataTypeParts.outOfGroup].map(
			({ id }) => id
		);
		result =
			dataTypesFilter.length === 0
				? result
				: result.filter((policy) => dataTypesFilter.some((dt) => policy.data_types.includes(dt)));

		return result;
	}, [policies, pageParams]);

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	function onResetFilters() {
		setPageParams({ ...pageParams, ...initialFilter });
	}

	const hasFilter =
		pageParams.type !== '' || pageParams.tags.length > 0 || pageParams['data-types'].length > 0;

	const generatePolicies = async () => {
		setGenerationLoading(true);

		try {
			await generatePoliciesFx();

			await CongratulationModal();
		} catch (error) {
			if (error instanceof APIError) {
				const { message } = await error.response.json();

				enqueueSnackbar(message);
			} else {
				throw error;
			}
		}

		setGenerationLoading(false);
	};

	// TODO case when no entries beause of filter

	return (
		<div>
			<Header>
				<Header.Breadcrumbs finalStep="Policies" />

				<Header.Title>Policies</Header.Title>

				<Header.CustomAction>
					<Select
						label={'New policy'}
						options={[DIM_ITEM, DAR_ITEM]}
						onChange={(newValue) => {
							const idPart = newValue === DIM_ITEM ? 'new' : 'new-dar';
							const path = generatePath(PATHS.POLICY_ITEM, { id: idPart });

							history.push(path);
						}}
						value=""
						// data-test="create-gateway-region-input"
						render={{ dropdownButton: DropdownButton }}
					/>
				</Header.CustomAction>
			</Header>

			<FilterLine
				config={['policyType', 'tags', 'dataTypesAll']}
				values={{
					policyType: pageParams.type as Filter['type'],
					tags: pageParams.tags,
					dataTypesAll: pageParams['data-types'],
				}}
				onChange={(newValues) => {
					onFilterLineUpdate({
						type: newValues.policyType,
						tags: newValues.tags,
						'data-types': newValues.dataTypesAll,
					});
				}}
			/>

			<PolicyTable policies={sortedAndFiltered} skeletonMode={skeletonMode} />

			{hasFilter && sortedAndFiltered.length === 0 && <ResetFilters onReset={onResetFilters} />}
			{skeletonMode && <NoPoliciesBlock loading={generationLoading} onClick={generatePolicies} />}
		</div>
	);
}

export { PolicyList };
