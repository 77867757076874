import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { generatePath, matchPath } from 'react-router';
import { CurtainOverlay } from 'components/Curtain';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayBlock from 'components/NewGatewayBlock';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { AssetDataFlowsTableItem } from 'models/assetDataFlows/dto';
import { assetDataFlowModel } from 'models/assetDataFlows/model';
import { TDataDirectionType } from 'models/common/dto';
import history from 'services/history';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { PATHS } from 'services/router';
import { DataFlowDetails } from 'views/SuperAssets/DataFlowDetails';
import tableConfig from './config';
import DataFlowTableItem from './DataFlowTableItem';
import styles from './index.module.css';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	name: {
		type: 'string',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = { assetId: number };

function DataFlowTable(props: Props) {
	const state = useStore(assetDataFlowModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [pageParams, setPageParams] = usePageParams(pageConfig, 'dataFlowAsset');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { sort, name } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			sort: { orderBy: sort.value as keyof AssetDataFlowsTableItem, order: sort.operator },
			assetId: props.assetId,
			name,
			'data-types': dataTypes,
		};

		assetDataFlowModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			assetDataFlowModel.resetFx();
		};
	}, []);

	// That's Table filter, not filters in Filter line.
	function onFilterUpdate(property: keyof AssetDataFlowsTableItem, filterText: string) {
		setPageParams({ ...pageParams, name: filterText });
	}

	function onSortUpdate(property: keyof AssetDataFlowsTableItem) {
		const { sort } = state.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		const newParams = {
			name: '',
			'data-types': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, hasMoreData, params, total_filtered, total } = state;
	const hasFilter =
		(params['data-types'].length > 0 && params['data-types'][0] !== 'nonempty') ||
		params.name !== '';

	const match: null | {
		params: {
			tab?: string;
			details_with?: string;
			flow_direction?: TDataDirectionType;
			id?: string;
		};
	} = matchPath(history.location.pathname, { path: PATHS.SERVICE_ITEM });
	const isCurtainOpen = match?.params.tab === 'data-flows' && !!match?.params.details_with;

	return (
		<>
			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={() => {}}
			/>

			<FilterResult
				entityLabel="data flow"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable data-test="asset-data-flow-table">
				<EnhancedTableHead
					config={tableConfig}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					filterBy={{ name: params.name }}
					onRequestSort={onSortUpdate}
					onRequestFilter={onFilterUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="data-flow-table-list">
						<>
							{data.length ? (
								data.map((assetDataFlow) => {
									return (
										<DataFlowTableItem
											assetDataFlow={assetDataFlow}
											key={assetDataFlow.interacts_with}
										/>
									);
								})
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="dataFlowData" className={styles.rowContainer}>
									<NewGatewayBlock />
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={assetDataFlowModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>

			<CurtainOverlay
				open={isCurtainOpen}
				onClose={() => {
					const newParams = { ...match?.params };
					delete newParams.details_with;
					delete newParams.flow_direction;

					const path = generatePath(PATHS.SERVICE_ITEM, newParams);
					history.replace(path + history.location.search);
				}}
				classes={{
					rightPartOpen: styles.curtainOpen,
				}}
				rightPart={
					<DataFlowDetails
						asset1={Number(match?.params.id)}
						asset2={Number(match?.params.details_with) || 0}
						direction={match?.params.flow_direction || 'none'}
					/>
				}
			/>
		</>
	);
}

export default DataFlowTable;
