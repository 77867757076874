import { Popper, ClickAwayListener } from '@material-ui/core';
import { Draft } from 'immer';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { DraftFunction } from 'use-immer';
import Typo from 'components/typography/Typo';
import { DARPolicyItem, DIMPolicyItem } from 'models/policiesV2/dto';
import { DARLocations } from 'views/PolicyV2/PolicyItem/PolicyItemForm/PolicyItemFormDAR';
import { DIMLocations } from 'views/PolicyV2/PolicyItem/PolicyItemForm/PolicyItemFormDIM';
import { HotKey } from '../HotKey';
import { RuleItemArray, RuleList } from '../RuleList';
import {
	darCategoriesOptions,
	dimCategoriesOptions,
	getEmptyGroup,
	locationByTypeName,
	parseRules,
	prepareDataForForm,
} from './helpers';
import styles from './index.module.css';
import OptionItem from './OptionItem';

interface Props {
	defaultRules: DIMPolicyItem['rules'] | DARPolicyItem['rules'];
	setFormData: (
		arg: DIMPolicyItem | DraftFunction<DIMPolicyItem> | DARPolicyItem | DraftFunction<DARPolicyItem>
	) => void;
	dim: boolean;
	locations: DARLocations & DIMLocations;
}

export type Option = {
	id: number | string;
	name: string;
};

export type ActiveRule = {
	groupId: number;
	index: number;
	key?: string;
	mainInputValue: string;
	ruleId: number;
	type: string;
	value?: string;
};

export function PolicyBuilder({ defaultRules, dim, setFormData, locations }: Props) {
	const [activeRule, setActiveRule] = React.useState<ActiveRule | null>(null);
	const [rules, setRules] = useState<RuleItemArray[]>([]);
	const [option, setOption] = useState<Option | undefined>();

	useEffect(() => {
		setRules([...parseRules(defaultRules, locations), getEmptyGroup()]);
		// setRules([...parseRules([]), getEmptyGroup()]);
		// setRules([...parseRules(fullMock), getEmptyGroup()]);
	}, []);

	useEffect(() => {
		if (option !== undefined && activeRule !== null) {
			setOption(undefined);
		}
	}, [activeRule, option]);

	const prepareOptions = useMemo(() => {
		if (activeRule) {
			const changeType = activeRule.value === undefined;
			const changeKey =
				activeRule.type.length > 0 &&
				activeRule.key !== undefined &&
				activeRule.value === undefined;
			const activeGroup = rules[activeRule.groupId];

			if (changeKey) {
				// @ts-ignore TODO
				return locations[locationByTypeName[activeRule.type]];
			} else if (changeType) {
				return (dim ? dimCategoriesOptions : darCategoriesOptions).filter((o) =>
					o.name.toLowerCase().includes(activeRule.type.toLowerCase())
				);
				// @ts-ignore TODO
			} else if (locations[locationByTypeName[activeRule.type]] && activeGroup) {
				const newServices = [...rules[activeRule.groupId].values];

				// When filtering, we need to use all the data except for the rule we are currently focused on.
				// Additionally, when moving within a group using clicks or keys,
				// the rule’s value should be visible in the Popover.
				if (activeRule.ruleId !== -1) {
					newServices.splice(activeRule.ruleId, 1);
				}

				if (activeRule.key && activeRule.key.length > 0) {
					const valuesByKey =
						// @ts-ignore TODO
						locations[locationByTypeName[activeRule.type]].find(
							(v: Option) => v.id === activeRule.key
						)?.values || [];

					const mapByValue = valuesByKey.map((v: string) => ({ id: v, name: v }));

					return [{ id: 'any', name: 'Any' }, { id: 'none', name: 'None' }, ...mapByValue]
						.filter((v: Option) => {
							return !newServices.includes(v.name);
						})
						.filter((v: string) => v !== activeRule.value);
				}

				// @ts-ignore TODO
				return [{ id: 'any', name: 'Any' }, ...locations[locationByTypeName[activeRule.type]]]
					.filter((v: Option) => !newServices.includes(v.name))
					.filter((v: Option) => {
						const name = v.name.toLowerCase();
						const activeRuleServiceName = activeRule.value?.toLowerCase() || '';

						return name.includes(activeRuleServiceName);
					});
			}

			return [];
		}

		return [];
	}, [activeRule, rules]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	function handleOpen(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function onClose() {
		setAnchorEl(null);
		setActiveRule(null);
		setOption(undefined);

		const dataForForm = prepareDataForForm(rules, dim, locations);

		setFormData((draft: Draft<DIMPolicyItem> | Draft<DARPolicyItem>) => {
			draft.rules = dataForForm;
		});
	}

	return (
		<ClickAwayListener onClickAway={onClose}>
			<div>
				<RuleList
					dim={dim}
					activeRule={activeRule}
					onActiveRuleChange={setActiveRule}
					onClick={handleOpen}
					onClose={onClose}
					onRulesChange={setRules}
					open={!!anchorEl}
					option={option}
					rules={rules}
				/>

				<Popper
					className={styles.popper}
					anchorEl={anchorEl}
					open={!!anchorEl}
					placement="bottom"
					style={{ width: anchorEl?.clientWidth }}
				>
					<div className={styles.options}>
						{prepareOptions.map((_option: Option) => (
							<OptionItem
								key={_option.id}
								option={_option}
								onClick={setOption}
								searchString={
									activeRule?.value ?? activeRule?.key ?? activeRule?.mainInputValue ?? ''
								}
								selected={false}
							/>
						))}

						{prepareOptions.length === 0 && (
							<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noOptionsBlock}>
								There are no matching results.
							</Typo>
						)}
					</div>

					<div className={styles.keysContainer}>
						{dim && <HotKey keys="ALT+1" action="AND" />}
						<HotKey keys="ALT+2" action="OR" />
						<HotKey keys="Backspace" action="To delete" className={styles.marginAuto} />
						<HotKey keys="Enter" action="To update query" />
						<HotKey keys="Close" action="Esc" />
					</div>
				</Popper>
			</div>
		</ClickAwayListener>
	);
}
