import cn from 'classnames';
import { ForwardedRef, MouseEvent, MouseEventHandler, ReactNode } from 'react';
import ButtonIcon, { ButtonIconProps } from 'components/ButtonIcon';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

interface Props {
	active?: boolean;
	buttonRef?: ForwardedRef<HTMLButtonElement>;
	children: ReactNode;
	className?: string;
	dataTest?: string;
	disabled?: boolean;
	kind?: 'accent' | 'primary-fixed' | 'secondary-fixed' | 'primary-removable';
	tooltip?: string;
	onClick?: MouseEventHandler<HTMLElement>;
	onClose?: MouseEventHandler<HTMLElement>;
	size?: 'M' | 'S';
}

enum IconColors {
	accent = 'icon/primary-inverted',
	'primary-fixed' = 'icon/primary',
	'secondary-fixed' = 'icon/primary',
	'primary-removable' = 'icon/primary',
}

enum IconSizes {
	'M' = 'S',
	'S' = 'XS',
}

enum KindStyles {
	accent = 'accent',
	'primary-fixed' = 'primaryFixed',
	'secondary-fixed' = 'secondaryFixed',
	'primary-removable' = 'primaryRemovable',
}

function getVariant(size: Props['size'], kind: Props['kind']) {
	if (kind === 'secondary-fixed') {
		return 'D/Regular/Body-S';
	} else if (size === 'M') {
		return 'D/Medium/Body-S';
	}

	return 'D/Medium/Meta';
}

function Filter({
	active,
	buttonRef,
	children,
	dataTest,
	disabled,
	className,
	kind = 'accent',
	tooltip = 'Clear filter',
	onClick,
	onClose,
	size = 'M',
}: Readonly<Props>) {
	function handleClose(event: MouseEvent<HTMLElement>) {
		if (onClose) {
			event.stopPropagation();

			onClose(event);
		}
	}

	function handleCloseFixed(event: MouseEvent<HTMLElement>) {
		const buttonContainer = event.currentTarget;
		const button = buttonContainer.parentElement!.parentElement!.firstChild as HTMLElement;

		button.click();
	}

	const buttonIconParams: ButtonIconProps = {
		icon: kind === 'primary-removable' ? 'Delete/Filled' : 'ChevronDown/Regular',
		color: IconColors[kind],
		size: IconSizes[size],
		className: styles.buttonClose,
	};

	// If we use one of the 'fixed' kind then we can't click on the close button.
	// There is just one behavior: click on the whole component
	if (kind.includes('fixed')) {
		buttonIconParams.onClick = handleCloseFixed;
	} else {
		buttonIconParams.onClick = handleClose;
	}

	return (
		<div
			className={cn(className, styles.container, styles[size], styles[KindStyles[kind]], {
				[styles.active]: active,
				[styles.disabled]: disabled,
			})}
			data-test={dataTest ?? 'filter-container'}
		>
			<button
				className={cn(styles.button, styles[`${KindStyles[kind]}Color`])}
				onClick={onClick}
				ref={buttonRef}
			>
				<Typo component="span" variant={getVariant(size, kind)} className={styles.buttonContent}>
					{children}
				</Typo>
			</button>

			<div className={styles.buttonCloseContainer}>
				{kind.includes('fixed') ? (
					<ButtonIcon {...buttonIconParams} />
				) : (
					<Tooltip title={tooltip} placement="top">
						<ButtonIcon dataTest="reset-filter" {...buttonIconParams} />
					</Tooltip>
				)}
			</div>
		</div>
	);
}

export default Filter;
