import cn from 'classnames';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

interface Props {
	action: string;
	keys: string;
	className?: string;
}

export function HotKey({ action, keys, className }: Props) {
	return (
		<div className={cn(styles.container, className)}>
			<Typo component="span" variant="D/Medium/Body-S" color="secondary">
				{action}
			</Typo>

			<Typo component="span" variant="D/Regular/Body-S-code" className={styles.key}>
				{keys}
			</Typo>
		</div>
	);
}
