import { PolicyRuleItem } from 'models/policies/dto';
import {
	DARPolicyItem,
	DARPolicyRuleItem,
	DIMPolicyItem,
	DIMPolicyRuleItem,
} from 'models/policiesV2/dto';
import { DARLocations } from 'views/PolicyV2/PolicyItem/PolicyItemForm/PolicyItemFormDAR';
import { DIMLocations } from 'views/PolicyV2/PolicyItem/PolicyItemForm/PolicyItemFormDIM';
import { RuleItemArray, RuleItemProps } from '../RuleList';

/* DATA BLOCK */
export const dimCategoriesOptions = [
	{ name: 'Service', id: 'asset' },
	{ name: 'Namespace', id: 'namespace' },
	{ name: 'Cluster', id: 'cluster' },
	{ name: 'Service label', id: 'label' },
	{ name: 'Namespace label', id: 'namespace_label' },
	{ name: 'Group', id: 'asset_group' },
	{ name: 'Region', id: 'region' },
];

export const darCategoriesOptions = [
	{ name: 'S3 bucket', id: 's3_bucket' },
	// { name: 'Kafka cluster', id: 'kafka_cluster' },
	{ name: 'Kafka topic', id: 'kafka_topic' },
	{ name: 'SQL database', id: 'sql_database' },
	{ name: 'No SQL database', id: 'nosql_database' },
	// { name: 'Region', id: 'region' },
];

export const locationByTypeName = {
	Service: 'assets',
	Namespace: 'namespaces',
	Cluster: 'clusters',
	'Service label': 'labels',
	'Namespace label': 'namespaceLabels',
	Group: 'groups',
	Region: 'regions',
	'S3 bucket': 's3buckets',
	// 'Kafka cluster': 'kafka_cluster',
	'Kafka topic': 'kafkaTopics',
	'SQL database': 'sqlDatabases',
	'No SQL database': 'nosqlDatabases',
};

const allCategories = [...dimCategoriesOptions, ...darCategoriesOptions];

// This is very important element that does every group unique that helps with rerender
let counter = 0;

export const AND_OPERATOR = 'AND';
export const OR_OPERATOR = 'OR';

export function getEmptyGroup(): RuleItemArray {
	return {
		index: counter++,
		type: '',
		key: '',
		operator: null,
		values: [],
	};
}

export function getOrRule(): RuleItemArray {
	return {
		index: counter++,
		type: '',
		key: '',
		operator: OR_OPERATOR,
		values: [],
	};
}

export function getAndRule(): RuleItemArray {
	return {
		index: counter++,
		type: '',
		key: '',
		operator: AND_OPERATOR,
		values: [],
	};
}

export function parseRules(
	rulesFromAPI: DIMPolicyItem['rules'] | DARPolicyItem['rules'],
	locations: DARLocations & DIMLocations
) {
	const localRules: RuleItemArray[] = [];

	function insertEmptyRules(_rules: RuleItemArray[]) {
		return _rules.flatMap((_rule) => [getEmptyGroup(), _rule]).slice(1);
	}

	function prepareRule(rule: DIMPolicyRuleItem | DARPolicyRuleItem) {
		const type = allCategories.find((c) => c.id === rule.type)?.name || rule.type;
		const preparedRule: RuleItemArray = {
			type,
			key: rule.key,
			values: rule.values,
			operator: null,
			index: counter++,
		};

		if (rule.type === 'asset_group') {
			preparedRule.values = rule.values.map(
				(value) => locations['groups'].find((group) => String(group.id) === value)?.name || value
			);
		} else if (rule.type === 'region') {
			preparedRule.values = rule.values.map(
				(value) => locations['regions'].find((region) => region.id === value)?.name || value
			);
		} else if (rule.type === 'cluster') {
			preparedRule.values = rule.values.map((value) => {
				return locations['clusters'].find((cluster) => String(cluster.id) === value)?.name || value;
			});
		}

		if (rule.type === 'namespace_label' || rule.type === 'label') {
			preparedRule.key = rule.key;
		}

		return preparedRule;
	}

	rulesFromAPI.forEach((rules: DIMPolicyRuleItem[] | DARPolicyRuleItem[], groupIndex) => {
		rules.forEach((rule, ruleIndex) => {
			localRules.push(prepareRule(rule));

			if (rules[ruleIndex + 1]) {
				localRules.push({
					type: '',
					key: '',
					values: [],
					operator: AND_OPERATOR,
					index: counter++,
				});
			}
		});

		if (rulesFromAPI[groupIndex + 1]) {
			localRules.push({
				type: '',
				key: '',
				values: [],
				operator: OR_OPERATOR,
				index: counter++,
			});
		}
	});

	return insertEmptyRules(localRules);
}

export function isEmptyRule(rule: RuleItemProps) {
	return (
		rule.type.length === 0 &&
		rule.values.length === 0 &&
		rule.operator === null &&
		rule.key.length === 0
	);
}

export function isOperator(rule: RuleItemProps) {
	return rule.type.length === 0 && rule.values.length === 0 && rule.operator !== null;
}

// TODO symplify
const listOfTypes = {
	Service: {
		type: 'asset',
		operator: 'is',
		key: '',
		values: [],
	},
	Namespace: { type: 'namespace', operator: 'is', key: '', values: [] },
	Cluster: {
		type: 'cluster',
		operator: 'is',
		key: '',
		values: [],
	},
	'Service label': {
		type: 'label',
		operator: 'is',
		key: '',
		values: [],
	},
	'Namespace label': {
		type: 'namespace_label',
		operator: 'is',
		key: '',
		values: [],
	},
	Group: {
		type: 'asset_group',
		operator: 'is',
		key: '',
		values: [],
	},
	Region: {
		type: 'region',
		operator: 'is',
		key: '',
		values: [],
	},
	'S3 bucket': {
		type: 's3_bucket',
		operator: 'is',
		key: '',
		values: [],
	},
	'Kafka cluster': {
		type: 'kafka_cluster',
		operator: 'is',
		key: '',
		values: [],
	},
	'Kafka topic': {
		type: 'kafka_topic',
		operator: 'is',
		key: '',
		values: [],
	},
	'SQL database': {
		type: 'sql_database',
		operator: 'is',
		key: '',
		values: [],
	},
	'No SQL database': {
		type: 'nosql_database',
		operator: 'is',
		key: '',
		values: [],
	},
};

export function prepareDataForForm(
	rules: RuleItemArray[],
	dim: boolean,
	locations: DARLocations & DIMLocations
) {
	const result: DIMPolicyRuleItem[][] | DARPolicyRuleItem[][] = [];

	// Filter rules from EmptyRules and OR
	let filteredRules: RuleItemArray[] = rules
		.filter((rule) => !isEmptyRule(rule))
		.filter((rule) => rule.operator !== AND_OPERATOR);

	// In the DAR
	if (!dim) {
		filteredRules = filteredRules.filter((rule) => rule.operator !== OR_OPERATOR);
	}

	let tmpRules: DIMPolicyRuleItem[] & DARPolicyRuleItem[] = [];

	if (dim) {
		filteredRules.forEach((rule) => {
			if (rule.operator === OR_OPERATOR) {
				result.push(tmpRules);
				tmpRules = [];
			} else {
				// @ts-ignore
				let newRule: DIMPolicyRuleItem | DARPolicyRuleItem = { ...listOfTypes['Service'] };

				// @ts-ignore
				if (listOfTypes[rule.type]) {
					// @ts-ignore
					newRule = { ...listOfTypes[rule.type] };
				} else {
					// @ts-ignore
					newRule.type = rule.type;
				}

				if (rule.values.length === 0) {
					if (newRule.type === 'label' || newRule.type === 'namespace_label') {
						newRule.operator = 'is_set';
					}
				} else if (newRule.type === 'label' || newRule.type === 'namespace_label') {
					if (newRule.values.length === 1 && newRule.values[0] === 'None') {
						newRule.values = [];

						newRule.operator = 'is_not_set';
					}
				} else if (newRule.type === 'asset_group') {
					newRule.values = rule.values.map((value) =>
						String(locations['groups'].find((group) => group.name === value)?.id || value)
					);
				} else if (newRule.type === 'region') {
					newRule.values = rule.values.map((value) =>
						String(locations['regions'].find((region) => region.name === value)?.id || value)
					);
				} else {
					newRule.values = rule.values;
				}

				if (rule.key) {
					newRule.key = rule.key;
				}
				// @ts-ignore
				tmpRules.push(newRule);
			}
		});

		result.push(tmpRules);
	} else {
		filteredRules.forEach((rule) => {
			// @ts-ignore
			let newRule: PolicyRuleItem = { ...listOfTypes['Service'] };

			// @ts-ignore
			if (listOfTypes[rule.type]) {
				// @ts-ignore
				newRule = { ...listOfTypes[rule.type] };
			} else {
				// @ts-ignore
				newRule.type = rule.type;
			}

			newRule.values = rule.values;
			// @ts-ignore
			result.push([newRule]);
		});
	}

	return result;
}

/* Mocks */
export const mockRules: PolicyRuleItem[][] = [
	[
		{
			key: '',
			operator: 'is',
			type: 'namespace',
			values: [],
		},
		{
			key: 'app.kubernetes.io/name',
			operator: 'is',
			type: 'label',
			values: ['PCI'],
		},
	],
	[
		{
			key: '',
			operator: 'is',
			type: 'namespace',
			values: ['vm'],
		},
	],
	[
		{
			key: '',
			operator: 'is',
			type: 'namespace',
			values: [],
		},
	],
];

export const fullMock: PolicyRuleItem[][] = [
	[
		{
			key: '',
			operator: 'is',
			type: 'namespace',
			values: ['ssltest', 'echo', 'grpc-test'],
		},
		{
			key: '',
			operator: 'is',
			type: 'asset',
			values: ['echo-server', 'flask-app', 'go-echo'],
		},
		{
			key: 'WSID',
			operator: 'is',
			type: 'label',
			values: ['ZmQ0YTQwNmYtN2Y0ZS00NDcyLTlhNDMtNjFlMTViYWEyMWNh'],
		},
		{
			key: 'app.kubernetes.io/name',
			operator: 'is',
			type: 'label',
			values: ['ingress-nginx', 'stage2'],
		},
		{
			key: 'addonmanager.kubernetes.io/mode',
			operator: 'is_not_set',
			type: 'namespace_label',
			values: [],
		},
		{
			key: 'pod-security.kubernetes.io/enforce',
			operator: 'is_set',
			type: 'namespace_label',
			values: [],
		},
		{
			key: 'kubernetes.io/metadata.name',
			operator: 'is',
			type: 'namespace_label',
			values: ['cert-manager', 'argocd', 'default', 'demo'],
		},
		{
			key: '',
			operator: 'is',
			type: 'asset_group',
			values: ['331'],
		},
		{
			key: '',
			operator: 'is',
			type: 'cluster',
			values: ['1'],
		},
	],
	[
		{
			key: '',
			operator: 'is',
			type: 'namespace',
			values: ['linkerd-injected', 'istio-injected'],
		},
		{
			key: '',
			operator: 'is',
			type: 'asset_group',
			values: [],
		},
		{
			key: '',
			operator: 'is',
			type: 'cluster',
			values: [],
		},
		{
			key: '',
			operator: 'is',
			type: 'asset',
			values: [],
		},
	],
];

export const labelsMock: PolicyRuleItem[][] = [
	[
		{
			key: '',
			operator: 'is',
			type: 'namespace',
			values: [],
		},
		{
			key: '',
			operator: 'is',
			type: 'asset',
			values: ['cart-behav-trck', 'echo-server'],
		},
		{
			key: 'app.kubernetes.io/managed-by',
			operator: 'is_set',
			type: 'label',
			values: [],
		},
		{
			key: 'istio.io/rev',
			operator: 'is_not_set',
			type: 'label',
			values: [],
		},
		{
			key: 'kubectl.kubernetes.io/default-container',
			operator: 'is',
			type: 'label',
			values: ['receiver', 'satellite'],
		},
		{
			key: 'addonmanager.kubernetes.io/mode',
			operator: 'is_set',
			type: 'namespace_label',
			values: [],
		},
		{
			key: 'admission.gatekeeper.sh/ignore',
			operator: 'is_not_set',
			type: 'namespace_label',
			values: [],
		},
		{
			key: 'app.kubernetes.io/instance',
			operator: 'is',
			type: 'namespace_label',
			values: ['ingress-nginx'],
		},
		{
			key: '',
			operator: 'is',
			type: 'asset_group',
			values: ['68', '69'],
		},
		{
			key: '',
			operator: 'is',
			type: 'cluster',
			values: ['5', '6', '7'],
		},
	],
];
