import { useStore } from 'effector-react';
import Typo from 'components/typography/Typo';
import { RecentActivityJson } from 'models/recentActivity/dto';
import { recentActivityStore } from 'models/recentActivity/store';
import { toAbbreviatedNumber } from 'services/numbers';
import { ApiCallsChart } from './ApiCallsChartOld';
import styles from './index.module.css';

function APICalls() {
	const data: RecentActivityJson = useStore(recentActivityStore);
	const total = data.api_calls.daily.reduce((acc, d) => acc + d.value, 0);

	return (
		<>
			<div className={styles.total}>
				<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
					Total
				</Typo>
				<Typo variant="D/Medium/H400-Page-Title">{toAbbreviatedNumber(total, 1)}</Typo>
			</div>

			<ApiCallsChart />
		</>
	);
}

export { APICalls };
