import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Badge from 'components/Badge';
import { CurtainOverlay } from 'components/Curtain';
import Loader from 'components/Loader';
import { PolicyBuilder } from 'components/ResourceField/PolicyBuilder';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { getAssets } from 'models/assets/api';
import { getAssetsExtended } from 'models/assetsExtended/api';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { getAssetGroups } from 'models/assetsGroups/api';
import { getClusterGeoLocationRegions } from 'models/clusterGeoLocations/api';
import { dataTypesPartsByIds } from 'models/dataTypes/helpers';
import { getGateways } from 'models/gateways/api';
import { getNamespaces } from 'models/namespaces/api';
import { DIMPolicyItem } from 'models/policiesV2/dto';
import {
	createPolicyFx,
	deletePolicyFx,
	togglePolicyTrackingFx,
	updatePolicyFx,
} from 'models/policiesV2/effects';
import { APIError } from 'services/api/httpRequest';
import { getPreviousPath } from 'services/history';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { pluralize } from 'services/strings';
import { LS_POLICY_DUPLICATE_KEY } from '..';
import { DeleteModal } from '../DeleteModal';
import { DataFlowMonitoring } from './DataFlowMonitoring';
import styles from './index.module.css';
import { PolicyAlert, usePolicyAlert } from './PolicyAlert';
import { PolicyDataTypesV2 } from './PolicyDataTypesV2';
import { PolicyDescription } from './PolicyDescription';
import { PolicyFormFooter } from './PolicyFormFooter';
import { PolicyFormHeader } from './PolicyFormHeader';
import { PolicyGroupFields } from './PolicyGroupFields';
import { PolicyName } from './PolicyName';
import { RuleCurtain, RuleCurtainProps } from './RuleCurtain';
import { Tags } from './Tags';
import { ThirdPartyAccess } from './ThirdPartyAccess';

type Props = {
	data: DIMPolicyItem;
};

const ALREADY_EXIST_REGEXP = /name/;
const DATA_TYPES_CANNOT_BE_EMPTY_REGEXP = /Data types/;

type LocationItem = { id: string | number; name: string; values?: string[] };
export type DIMLocations = {
	assets: LocationItem[];
	namespaces: LocationItem[];
	clusters: LocationItem[];
	groups: LocationItem[];
	regions: LocationItem[];
	labels: LocationItem[];
	namespaceLabels: LocationItem[];
};

export const PolicyItemFormDIM = ({ data }: Props) => {
	const [isAlertShown, hideAlert] = usePolicyAlert();
	const [displayRuleCurtain, setDisplayRuleCurtain] = useState(false);
	const [formData, setFormData] = useImmer(data);
	const [policyNameError, setPolicyNameError] = useState('');
	const [locations, setLocations] = useState<DIMLocations | null>(null);
	const [internalAssets, setInternalAssets] = useState<TAssetsItem[]>([]);

	const dataTypesParts = useMemo(() => {
		return dataTypesPartsByIds(formData.data_types);
	}, [formData.data_types]);

	useEffect(() => {
		function simplifyData(dimItems: { id: string | number; name: string }[]): LocationItem[] {
			return dimItems
				.map((dimItem) => ({
					id: dimItem.id,
					name: dimItem.name,
				}))
				.sort((a, b) => a.name.localeCompare(b.name))
				.filter((dimItem, i, arr) => {
					const previousItem = arr[i - 1];
					if (!previousItem) return true;

					return dimItem.name !== previousItem.name;
				});
		}

		function collectLabels(labels: { key: string; value: string }[]) {
			const labelList: { id: string; name: string; values: string[] }[] = [];

			for (const label of labels) {
				const foundLabel = labelList.find((l) => l.name === label.key);

				if (foundLabel) {
					foundLabel.values.push(label.value);
				} else {
					labelList.push({
						id: label.key,
						name: label.key,
						values: [label.value],
					});
				}
			}

			labelList.sort((a, b) => a.name.localeCompare(b.name));

			for (const l of labelList) {
				l.values = Array.from(new Set(l.values)).sort((a, b) => a.localeCompare(b));
			}

			return labelList;
		}

		Promise.all([
			getAssets(), // also namespaces and labels
			getGateways(),
			getNamespaces(), // ONLY labels
			getAssetGroups(),
			getClusterGeoLocationRegions(),
		])
			.then(([assetsRaw, gatewaysRaw, namespaceLabelsRaw, groupsRaw, regionsRaw]) => {
				const internalAssetsRaw = assetsRaw.assets.filter((assetRaw) => !assetRaw.is_external);

				const assets = simplifyData(internalAssetsRaw);
				const namespaces = simplifyData(
					internalAssetsRaw.map((assetRaw) => ({
						id: assetRaw.namespace,
						name: assetRaw.namespace,
					}))
				);
				const clusters = simplifyData(gatewaysRaw);
				const groups = simplifyData(groupsRaw.groups);
				const regions = simplifyData(
					regionsRaw.regions.map((regionRaw) => ({
						id: regionRaw.keyword,
						name: regionRaw.description,
					}))
				);

				const labels = collectLabels(internalAssetsRaw.flatMap((assetRaw) => assetRaw.labels));
				const namespaceLabels = collectLabels(namespaceLabelsRaw.labels);

				const dimData = {
					assets,
					namespaces,
					clusters,
					groups,
					regions,
					labels,
					namespaceLabels,
				};

				setLocations(dimData);
			})
			.catch((e) => {
				console.error(e);
				enqueueSnackbar('Something went wrong when fetching locations');
			});
	}, []);

	useEffect(() => {
		getAssetsExtended({ limit: 1000, offset: 0, is_external: false }).then((internalAssetsRaw) => {
			setInternalAssets(internalAssetsRaw.assets);
		});
	}, []);

	const foundNamespaces: RuleCurtainProps['namespaces'] = useMemo(() => {
		const dataTypesSelected = dataTypesParts.dataTypesSource.map(({ id }) => id);

		return internalAssets
			.filter(({ data_types }) => data_types.some((dtId) => dataTypesSelected.includes(dtId)))
			.reduce(
				(acc, asset) => {
					const namespace = acc.find(({ name }) => name === asset.namespace);

					if (namespace) {
						namespace.assets.push({
							id: asset.id,
							name: asset.name,
							dataTypes: asset.data_types,
						});
					} else {
						acc.push({
							name: asset.namespace,
							assets: [{ id: asset.id, name: asset.name, dataTypes: asset.data_types }],
						});
					}

					return acc;
				},
				[] as RuleCurtainProps['namespaces']
			);
	}, [internalAssets, dataTypesParts]);

	const topRef = useRef<HTMLDivElement>(null);
	const detailsSectionRef = useRef<HTMLDivElement>(null);
	const dataTypesSectionRef = useRef<HTMLDivElement>(null);
	const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		setFormData(data);
	}, [data]);

	const history = useHistory();

	const isNew = formData.id === 0;

	const addRules = useCallback((rules) => {
		setFormData((draft) => {
			draft.rules.push(...rules);
		});
	}, []);

	const setActive = useCallback((e) => {
		setFormData((draft) => {
			draft.is_active = e.target.checked;
		});
	}, []);

	const savePolicy = async () => {
		if (formData.name.length > 255) {
			enqueueSnackbar('Maximum Name length is 255');
			return;
		}

		const saveHandler = isNew ? createPolicyFx : updatePolicyFx;

		try {
			await saveHandler({
				...formData,
				data_types: [...dataTypesParts.groups, ...dataTypesParts.outOfGroup].map(({ id }) => id),
			});
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();

				switch (true) {
					case ALREADY_EXIST_REGEXP.test(message):
						scrollToTop(detailsSectionRef);
						setPolicyNameError(message);
						break;

					case DATA_TYPES_CANNOT_BE_EMPTY_REGEXP.test(message):
						scrollToTop(dataTypesSectionRef);
						enqueueSnackbar(message);
						break;

					default:
						scrollToTop(topRef);
						enqueueSnackbar(message);
				}

				return;
			}

			throw error;
		}

		history.push(PATHS.POLICY_LIST);
		enqueueSnackbar('Policy has been saved');
	};

	const deletePolicy = async () => {
		await DeleteModal({
			policyName: data.name,
			onConfirm: async () => {
				try {
					await deletePolicyFx(formData.id);
					enqueueSnackbar('Policy has been deleted');

					if (getPreviousPath() === PATHS.POLICY_LIST) {
						history.goBack();
					} else {
						history.replace(PATHS.POLICY_LIST);
					}
				} catch (error) {
					if (error instanceof APIError) {
						const { message } = await error.response.json();

						enqueueSnackbar(message);
					}
				}
			},
		});
	};

	const switchTracking = async () => {
		setFormData((draft) => {
			draft.is_active = !draft.is_active;

			togglePolicyTrackingFx({ id: draft.id, is_active: draft.is_active }).catch(() => {
				enqueueSnackbar('Something went wrong');
			});
		});
	};

	const duplicatePolicy = useCallback(() => {
		localStorage.setItem(LS_POLICY_DUPLICATE_KEY, JSON.stringify(formData));
	}, [formData]);

	return (
		<div className={styles.container} ref={topRef}>
			<PolicyFormHeader
				isNew={isNew}
				type={formData.type}
				deletePolicy={deletePolicy}
				duplicatePolicy={duplicatePolicy}
				tracking={formData.is_active}
				switchTracking={switchTracking}
				name={data.name}
			/>

			<PolicyGroupFields title="Details" hrBottom>
				<div className={styles.name}>
					<PolicyName value={formData.name} setFormData={setFormData} />
				</div>
				<div className={styles.description}>
					<PolicyDescription value={formData.description} setFormData={setFormData} />
				</div>

				<Tags value={formData.tags} options={formData.tags} setFormData={setFormData} />
			</PolicyGroupFields>

			<PolicyGroupFields
				title="Data types"
				hrBottom
				topRightBlock={
					<Typo
						dataTest="policy-namespaces-count"
						variant="D/Medium/Body-S"
						className={cn(styles.namespaceFound, foundNamespaces.length === 10 && styles.disabled)}
						onClick={() => setDisplayRuleCurtain(!displayRuleCurtain)}
					>
						Found in <Badge badgeContent={toLocaleString(foundNamespaces.length)} />{' '}
						{pluralize('namespace', foundNamespaces.length)}
					</Typo>
				}
			>
				<PolicyDataTypesV2 value={formData.data_types} setFormData={setFormData} />
			</PolicyGroupFields>

			<PolicyGroupFields
				title="Service access"
				description="Services that will be allowed to access selected data types."
				hrBottom
			>
				{locations === null ? (
					<Loader />
				) : (
					<PolicyBuilder
						defaultRules={formData.rules}
						/* @ts-ignore */
						setFormData={setFormData}
						/* @ts-ignore */
						locations={locations}
						dim
					/>
				)}
			</PolicyGroupFields>

			<PolicyGroupFields title="Third party access" hrBottom>
				<ThirdPartyAccess
					allow_external_transfer={formData.allow_external_transfer}
					allowed_external_assets={formData.allowed_external_assets}
					setFormData={setFormData}
				/>
			</PolicyGroupFields>

			<PolicyGroupFields title="Data flow monitoring" hrBottom={false}>
				<DataFlowMonitoring
					allow_encrypted_network_only={formData.allow_encrypted_network_only}
					setFormData={setFormData}
				/>

				{isAlertShown && (
					<PolicyAlert
						header="Only new traffic will be tracked"
						onClose={hideAlert}
						className={styles.alert}
					>
						After saving, only future API requests will be checked for violations; past requests
						will not be evaluated.
					</PolicyAlert>
				)}
			</PolicyGroupFields>

			<PolicyFormFooter
				isNew={isNew}
				isError={!!policyNameError}
				saveForm={savePolicy}
				isActive={formData.is_active}
				setActive={setActive}
			/>

			<CurtainOverlay
				open={displayRuleCurtain}
				onClose={() => setDisplayRuleCurtain(false)}
				rightPart={
					<RuleCurtain
						type="dim"
						namespaces={foundNamespaces}
						onChange={(rules) => {
							addRules(rules);
							setDisplayRuleCurtain(false);
						}}
					/>
				}
				classes={{ hideButton: styles.hideButton }}
			/>
		</div>
	);
};
