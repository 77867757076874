import { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { dayjs, format, DateFormat } from 'services/dayjs';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import styles from './index.module.css';

const testData = [
	{
		id: 0,
		name: 'Sensitive data found in unauthorized asset',
		time: +dayjs().subtract(2, 'day').add(1, 'hour').startOf('minute'),
		resource: 'cards',
		account: 'sale-report',
	},
	{
		id: 0,
		name: 'Unencrypted storage with sensitive data',
		time: +dayjs().subtract(2, 'day').startOf('day').add(25, 'minute').startOf('minute'),
		resource: 'cards',
		account: 'sale-report',
	},
	{
		id: 0,
		name: 'Publicly available storage with sensitive data',
		time: +dayjs().subtract(3, 'day').startOf('day').add(15, 'minute').startOf('minute'),
		resource: 'trd-data-store',
		account: 'fin-reports',
	},
	{
		id: 0,
		name: 'Sensitive data found in unauthorized asset',
		time: +dayjs().subtract(5, 'day').add(1, 'hour').add(11, 'minute').startOf('minute'),
		resource: 'cards',
		account: 'sale-report',
	},
	{
		id: 0,
		name: 'Sensitive data found in unauthorized asset',
		time: +dayjs().subtract(5, 'day').add(4, 'hour').add(43, 'minute').startOf('minute'),
		resource: 'cards',
		account: 'sale-report',
	},
];

type Props = {
	data: typeof testData;
};

function HighPriorityEvents(props: Props) {
	const events = props.data;

	return (
		<div className={styles.container}>
			{events.map((event, i) => (
				<RouterLink to={'' && `${PATHS.EVENTS}?id=${event.id}`} className={styles.link} key={i}>
					<div className={styles.event}>
						<Icon name="warning" size={16} className={styles.redIcon} />

						<div>
							<div>{event.name}</div>
							<Typo variant="D/Regular/Meta-S" color="secondary">
								{getStringDividedByDot(
									format(event.time, DateFormat.cutDate),
									`Resource: ${event.resource}`,
									`Account: ${event.account}`
								)}
							</Typo>
						</div>
					</div>
				</RouterLink>
			))}
		</div>
	);
}

type WrapperProps = {
	setEventsCount: (c: number) => void;
};

function Wrapper(props: WrapperProps) {
	const { setEventsCount } = props;

	const [data, setData] = useState<typeof testData | null>(null);

	useEffect(() => {
		// call api
		setTimeout(() => {
			setEventsCount(testData.length);
			setData(testData);
		}, 500);
	}, []);

	return data === null ? <Loader /> : <HighPriorityEvents data={data} />;
}

export { Wrapper as HighPriorityEvents };
