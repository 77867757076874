import { useCallback, useState } from 'react';
import { DraftFunction } from 'use-immer';
import TextField from '../../../../../components/form/TextField';
import { DIMPolicyItem } from '../../../../../models/policiesV2/dto.ts';
import styles from '../index.module.css';

type Props = {
	value: string;
	setFormData: (arg: DIMPolicyItem | DraftFunction<DIMPolicyItem>) => void;
};

export const PolicyName = (props: Props) => {
	const { value, setFormData } = props;

	const [error, setError] = useState('');

	const setName = useCallback((name) => {
		setFormData((draft) => {
			draft.name = name;
		});

		if (name.length > 255) {
			setError('Maximum Name length is 255');
		} else {
			setError('');
		}
	}, []);

	return (
		<div className={styles.name}>
			<TextField
				dataTest="policy-name-input"
				placeholder="Name"
				label="Name"
				value={value}
				onChange={(e) => setName(e.target.value)}
				helperText={error}
				error={!!error}
				optional={false}
			/>
		</div>
	);
};
