import cn from 'classnames';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getAssetCounters } from 'models/assetCounters/api';
import { AssetCountersJson } from 'models/assetCounters/dto';
import { getAssetDataFlows, getAssetConnections } from 'models/assetDataFlows/api';
import { AssetDataFlowsTableItem, AssetConnectionsTableItem } from 'models/assetDataFlows/dto';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { getEndpoints } from 'models/endpointsV2/api';
import { EndpointsTableItem, methodOrProtocol } from 'models/endpointsV2/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { toLocaleString, toAbbreviatedNumber, toRPS } from 'services/numbers';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import DataFlowIcon from 'views/SuperAssets/DataFlowIcon';
import { MapAsset } from '../../../index';
import { changeMapControls, mapControlsStore, unselectInteractsWith } from '../../model/store';
import commonStyles from '../index.module.css';
import styles from './index.module.pcss';

type Props = {
	asset: MapAsset;
};

function DetailsContent(props: Props) {
	const { asset } = props;

	const history = useHistory();

	const mapControls = useStore(mapControlsStore);
	const gatewaysNamesStore = useStore(gatewaysNamesById);

	const [counters, setCounters] = useState<AssetCountersJson | null>(null);
	const [dataflows, setDataflows] = useState<AssetDataFlowsTableItem[]>([]);
	const [connections, setConnections] = useState<AssetConnectionsTableItem[]>([]);
	const [endpoints, setEndpoints] = useState<EndpointsTableItem[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		Promise.all([
			getAssetCounters(asset.entityId),
			getEndpoints({
				asset: asset.entityId,
				limit: 100,
			}),
			getAssetDataFlows(asset.entityId, { limit: 100 }),
			getAssetConnections(asset.entityId, { limit: 100 }),
		])
			.then(([c, e, df, cnn]) => {
				setCounters(c);
				setEndpoints(e.endpoints);
				setDataflows(df.dataflows);
				setConnections(cnn.dataflows);
			})
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			})
			.finally(() => {
				setLoading(false);
			});

		return unselectInteractsWith;
	}, [asset]);

	function onDataFlowClick(interactsWith: TAssetsItem['id']) {
		const newControls = produce(mapControls, (draft) => {
			draft.interactsWith.selected =
				interactsWith === draft.interactsWith.selected?.id
					? null
					: { id: interactsWith, type: 'asset' };
		});

		changeMapControls(newControls);
	}

	function onDataFlowMouseEnter(interactsWith: TAssetsItem['id']) {
		const newControls = produce(mapControls, (draft) => {
			draft.interactsWith.hovered = { id: interactsWith, type: 'asset' };
		});

		changeMapControls(newControls);
	}

	function onDataFlowMouseLeave() {
		const newControls = produce(mapControls, (draft) => {
			draft.interactsWith.hovered = null;
		});

		changeMapControls(newControls);
	}

	return (
		<div className={commonStyles.detailsContainer}>
			<Preloader isLoading={loading}>
				<div className={commonStyles.scrollContainer}>
					<Accordion defaultExpanded={true} className={commonStyles.sidePaddings}>
						<AccordionSummary>
							Data flows{' '}
							<Typo variant="D/Regular/Body-S" color="secondary" className={styles.secondaryNumber}>
								{toLocaleString(counters?.dataflows)}
							</Typo>
						</AccordionSummary>

						<AccordionDetails>
							<GridTable className={commonStyles.table}>
								<GridHead>
									<GridRow className={styles.dataflowRowContainer} border>
										<GridCell head className={styles.gridCellHead}>
											Flow
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											Interacts with
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											Data types
										</GridCell>
										<GridCell head align="right" className={styles.gridCellHead}>
											Requests per day / avg RPS
										</GridCell>
									</GridRow>
								</GridHead>

								<GridBody className={styles.tableBody}>
									{dataflows.map((dataflow, i) => {
										const {
											flow_direction,
											name,
											type,
											cluster_id,
											k8s_types,
											data_types,
											interacts_with,
											estimated_requests,
										} = dataflow;

										const displayName = getAssetNameByType(type, name);
										const isActive = mapControls.interactsWith.selected?.id === interacts_with;

										return (
											<GridRow
												key={i}
												className={styles.dataflowRowContainer}
												onClick={() => onDataFlowClick(interacts_with)}
												border
												hover
												active={isActive}
												onMouseEnter={() => onDataFlowMouseEnter(interacts_with)}
												onMouseLeave={onDataFlowMouseLeave}
											>
												<GridCell className={cn(styles.flowDirection)}>
													<DataFlowIcon
														direction={flow_direction}
														className={styles.dataflowIcon}
													/>
													{dataflow.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
													{dataflow.is_mesh_network && (
														<Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />
													)}
												</GridCell>

												<GridCell className={styles.gapCell}>
													<Typo variant="D/Medium/Meta">{displayName}</Typo>

													<Typo variant="D/Regular/Meta" color="secondary" className={styles.flex}>
														{getStringDividedByDot(k8s_types, gatewaysNamesStore[cluster_id])}
													</Typo>
												</GridCell>

												<GridCell>
													<PiiTypeList noHighlight data={data_types} size="extraSmall" />
												</GridCell>

												<GridCell align="right">
													<Typo variant="D/Regular/Meta" component="span">
														{toAbbreviatedNumber(estimated_requests)}
													</Typo>
													<Typo
														variant="D/Regular/Meta"
														component="span"
														className={styles.withLeftSeparator}
													>
														{toRPS(estimated_requests)}
													</Typo>
												</GridCell>

												<div
													className={cn(
														{ [styles.activeDetailDataFlow]: isActive },
														styles.detailedDataFlow
													)}
												>
													<RouterLink
														to={generatePath(PATHS.SERVICE_ITEM, {
															id: asset.entityId,
															tab: 'data-flows',
															flow_direction: dataflow.flow_direction,
															details_with: dataflow.interacts_with,
														})}
													>
														<ButtonIcon icon="Open/Regular" />
													</RouterLink>
												</div>
											</GridRow>
										);
									})}
								</GridBody>

								<Button
									color="tertiary"
									size="small"
									endIcon={<Icon name="Open/Regular" size={20} />}
									fullWidth
									href={generatePath(PATHS.SERVICE_ITEM, { id: asset.entityId, tab: 'data-flows' })}
									target="_blank"
									className={styles.detailedInfo}
								>
									View detailed info
								</Button>
							</GridTable>
						</AccordionDetails>
					</Accordion>

					<Accordion defaultExpanded={true} className={commonStyles.sidePaddings}>
						<AccordionSummary>
							Connections{' '}
							<Typo variant="D/Regular/Body-S" color="secondary" className={styles.secondaryNumber}>
								{toLocaleString(counters?.storage_connections)}
							</Typo>
						</AccordionSummary>

						<AccordionDetails>
							<GridTable className={commonStyles.table}>
								<GridHead>
									<GridRow className={styles.connectionRowContainer} border>
										<GridCell>{''}</GridCell>
										<GridCell head className={styles.gridCellHead}>
											Interacts with
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											Stored data types
										</GridCell>
									</GridRow>
								</GridHead>

								<GridBody className={styles.tableBody}>
									{connections.map((dataflow, i) => {
										const { name, interacts_with, data_types } = dataflow;

										const isActive = mapControls.interactsWith.selected?.id === interacts_with;

										return (
											<GridRow
												key={i}
												className={styles.connectionRowContainer}
												onClick={() => onDataFlowClick(interacts_with)}
												border
												hover
												active={isActive}
												onMouseEnter={() => onDataFlowMouseEnter(interacts_with)}
												onMouseLeave={onDataFlowMouseLeave}
											>
												<GridCell withoutBlur className={styles.icons}>
													{dataflow.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
													{dataflow.is_mesh_network && (
														<Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />
													)}
												</GridCell>

												<GridCell>
													<Typo variant="D/Medium/Meta">{name}</Typo>
												</GridCell>

												<GridCell>
													<PiiTypeList noHighlight data={data_types} size="extraSmall" />
												</GridCell>
											</GridRow>
										);
									})}
								</GridBody>

								<Button
									color="tertiary"
									size="small"
									endIcon={<Icon name="Open/Regular" size={20} />}
									fullWidth
									href={generatePath(PATHS.SERVICE_ITEM, {
										id: asset.entityId,
										tab: 'connections',
									})}
									target="_blank"
									className={styles.detailedInfo}
								>
									View detailed info
								</Button>
							</GridTable>
						</AccordionDetails>
					</Accordion>

					{asset.type === 'internal' && (
						<Accordion className={commonStyles.sidePaddings}>
							<AccordionSummary>
								Endpoints{' '}
								<Typo
									variant="D/Regular/Body-S"
									color="secondary"
									className={styles.secondaryNumber}
								>
									{toLocaleString(counters?.endpoints)}
								</Typo>
							</AccordionSummary>
							<AccordionDetails>
								<GridTable className={commonStyles.table}>
									<GridHead>
										<GridRow className={styles.endpointRowContainer} border>
											<GridCell>{''}</GridCell>
											<GridCell head className={styles.gridCellHead}>
												Endpoint
											</GridCell>
											<GridCell head className={styles.gridCellHead}>
												Data types
											</GridCell>
											<GridCell head align="right" className={styles.gridCellHead}>
												Requests per day / avg RPS
											</GridCell>
										</GridRow>
									</GridHead>

									<GridBody className={styles.tableBody}>
										{endpoints.map((endpoint) => {
											const { id, data_types, url, process_name, estimated_requests } = endpoint;

											return (
												<GridRow
													key={id}
													className={styles.endpointRowContainer}
													onClick={() => {
														const path = `${PATHS.SAMPLE_V2}?id=${id}`;
														history.push(path);
													}}
													border
													hover
												>
													<GridCell withoutBlur className={styles.icons}>
														{endpoint.is_encrypted && (
															<Icon name="tlsRegular" dataTest="tls-icon" />
														)}
														{endpoint.is_mesh_network && (
															<Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />
														)}
													</GridCell>
													<GridCell>
														<Typo variant="D/Medium/Meta">{url}</Typo>
														<Typo
															variant="D/Regular/Meta"
															color="secondary"
															className={styles.method}
														>
															{methodOrProtocol(endpoint)}
														</Typo>

														{process_name && (
															<Typo
																variant="D/Regular/Meta"
																color="secondary"
																className={styles.processName}
															>
																Process: {process_name}
															</Typo>
														)}
													</GridCell>

													<GridCell>
														<PiiTypeList noHighlight data={data_types} size="extraSmall" />
													</GridCell>

													<GridCell align="right">
														<Typo variant="D/Regular/Meta">
															{toAbbreviatedNumber(estimated_requests)}
														</Typo>
														<Typo variant="D/Regular/Meta" className={styles.withLeftSeparator}>
															{toRPS(estimated_requests)}
														</Typo>
													</GridCell>
												</GridRow>
											);
										})}
									</GridBody>

									<Button
										color="tertiary"
										size="small"
										endIcon={<Icon name="Open/Regular" size={20} />}
										fullWidth
										href={generatePath(PATHS.SERVICE_ITEM, {
											id: asset.entityId,
											tab: 'endpoints',
										})}
										target="_blank"
										className={styles.detailedInfo}
									>
										View detailed info
									</Button>
								</GridTable>
							</AccordionDetails>
						</Accordion>
					)}
				</div>
			</Preloader>
		</div>
	);
}

export default DetailsContent;
